import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ViewOkrsCycleComponent } from '../view-okrs-cycle/view-okrs-cycle.component';
import { CoreService } from 'app/shared/services/core.service';
import {
  ObjSheetPopupComponent,
  REFERRAL_ACTION,
  REFERRAL,
  VIEW_MODE,
} from 'app/company-report/objectives-individual/objective/obj-sheet-popup/obj-sheet-popup.component';
import { CycleManagerService } from 'app/components/common/cycle-manager/cycle-manager.service';
import { CompanyReportService } from 'app/company-report/company-report.service';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
// import { CycleManagerComponent } from "app/components/common/cycle-manager/cycle-manager.component";
import { CyclesUtilitiesService } from 'app/shared/services/cycles-utilities.service';
import { max } from 'moment';

@Component({
  selector: 'manager-okr-popup',
  templateUrl: './manager-okr-popup.component.html',
  styleUrls: ['./manager-okr-popup.component.scss'],
})
export class ManagerOkrPopupComponent implements OnInit {
  response: any = [];
  @Input() currentStatus: any;
  cycle: any = [];
  @Output() reload = new EventEmitter();
  @Input() currentFilter: string;
  @Input() currentSearch: string;
  filteredEmployees: any;
  currentUserInfo: any;
  iamAdmin: boolean;
  isWeighted: boolean = true;
  isNoItemAvailable: boolean = false;

  type: any;
  data: any;
  description: any = '';
  bsModalRef: BsModalRef;
  isOkrFilter: boolean = false;
  showTextField: boolean = false;
  textValue: any = '';
  originalFilteredEmployees: any;

  employeeList: any = [
    {
      title: 'Increase sales',
      name: 'Amelia Ferri',
      image: '',
      Id: '11',
    },
    {
      title: 'Brand awareness',
      name: 'Amelia Ferri',
      image: '',
      Id: '12',
    },
    {
      title: 'OKR',
      name: 'Angelo Vasseri',
      image: '',
      Id: '13',
    },
  ];

  cycleData = [
    {
      cycleName: {
        title: 'Objective Cycle 2021',
        okr: 13,
        partecipant: 8,
      },
      expected: '50',
      actualProgress: '64',
      expectedPercentage: true,
      expectedPositive: true,
      timeLeft: '1 week left',
      timePassed: '72%',
      monitorCycle: true,
    },
    {
      cycleName: { title: 'Objective Cycle 2021', okr: 13, partecipant: 14 },
      expected: '50',
      actualProgress: '20',
      expectedPercentage: true,
      expectedPositive: false,
      timeLeft: '1 month left',
      timePassed: '72%',
      monitorCycle: true,
    },
    {
      cycleName: { title: 'Objective Cycle 2021', okr: 13, partecipant: 14 },
      expected: '50',
      actualProgress: '64',
      expectedPercentage: true,
      expectedPositive: true,
      timeLeft: '1 week left',
      timePassed: '72%',
      monitorCycle: true,
    },
    {
      cycleName: { title: 'Objective Cycle 2021', okr: 13, partecipant: 14 },
      expected: '50',
      actualProgress: '64',
      expectedPercentage: true,
      expectedPositive: true,
      timeLeft: '1 week left',
      timePassed: '72%',
      monitorCycle: true,
    },
    {
      cycleName: { title: 'Objective Cycle 2021', okr: 13, partecipant: 14 },
      expected: '50',
      actualProgress: '64',
      expectedPercentage: true,
      expectedPositive: true,
      timeLeft: '1 week left',
      timePassed: '72%',
      monitorCycle: true,
    },
  ];

  okrData = [
    {
      id: '1',
      okrName: {
        title: 'Increase Sales Closing Rate by 15%',
        name: 'Fabio Fedel',
      },
      weight: '72%',
      expected: '50',
      actualProgress: '10',
      timeLeft: '1 week left',
      expectedPercentage: true,
      expectedPositive: false,
      timePassed: '72%',
      lastUpdate: 'dd/mm/yyyy',
      nudge: true,
    },
    {
      id: '2',
      okrName: {
        title: 'Increase Sales Closing Rate by 15%',
        name: 'Fabio Fedel',
      },
      weight: '72%',
      expected: '50',
      actualProgress: '10',
      timeLeft: '1 week left',
      expectedPercentage: true,
      expectedPositive: false,
      timePassed: '72%',
      lastUpdate: 'dd/mm/yyyy',
      nudge: true,
    },
    {
      id: '3',
      okrName: {
        title: 'Increase Sales Closing Rate by 15%',
        name: 'Fabio Fedel',
      },
      weight: '72%',
      expected: '50',
      actualProgress: '10',
      timeLeft: '1 week left',
      expectedPercentage: true,
      expectedPositive: false,
      timePassed: '72%',
      lastUpdate: 'dd/mm/yyyy',
      nudge: true,
    },
    {
      id: '4',
      okrName: {
        title: 'Increase Sales Closing Rate by 15%',
        name: 'Fabio Fedel',
      },
      weight: '72%',
      expected: '50',
      actualProgress: '10',
      timeLeft: '1 week left',
      expectedPercentage: true,
      expectedPositive: false,
      timePassed: '72%',
      lastUpdate: 'dd/mm/yyyy',
      nudge: true,
    },
    {
      id: '5',
      okrName: {
        title: 'Increase Sales Closing Rate by 15%',
        name: 'Fabio Fedel',
      },
      weight: '72%',
      actualProgress: '10',
      expected: '50',
      timeLeft: '1 week left',
      expectedPercentage: true,
      expectedPositive: false,
      timePassed: '72%',
      lastUpdate: 'dd/mm/yyyy',
      nudge: true,
    },
  ];

  employeeData = [
    {
      id: 1,
      name: 'Fabio Fedel',
      image: '../../../../assets/images/amelia_icon.jpg',
      okrCount: '6',
      actualProgress: '20',
      expected: '50',
      expectedPositive: false,
      okr: [
        {
          id: 11,
          okrName: 'Increase Sales Closing Rate by 15%',
          weight: '72%',
          actualProgress: '30',
          expected: '50',
          expectedPositive: false,
          dueDate: 'dd/mm/yyyy',
          nudge: true,
        },
        {
          id: 12,
          okrName: 'Increase Sales Closing Rate by 15%',
          weight: '72%',
          actualProgress: '30',
          expected: '50',
          expectedPositive: false,
          dueDate: 'dd/mm/yyyy',
          nudge: true,
        },
      ],
    },
    {
      id: 2,
      name: 'Fabio Fedel',
      image: '../../../../assets/images/amelia_icon.jpg',
      actualProgress: '20',
      expected: '50',
      expectedPositive: false,
      okrCount: '6',
      okr: [
        {
          id: 21,
          okrName: 'Increase Sales Closing Rate by 15%',
          weight: '72%',
          actualProgress: '30',
          expected: '50',
          expectedPositive: false,
          dueDate: 'dd/mm/yyyy',
          nudge: true,
        },
      ],
    },
  ];
  okr5 = [
    {
      id: '1',
      plus: true,
      digit: 8,
      description: 'Employees without OKRs',
    },
    {
      id: '2',
      plus: true,
      digit: 6,
      description: 'OKRs awaiting approval',
    },
    {
      id: '3',
      plus: true,
      digit: 2,
      description: 'Unweighted OKRs',
    },
    {
      id: '4',
      plus: true,
      digit: 1,
      maxValue: 10,
      description: 'OKRs without links',
    },
    {
      id: '5',
      plus: true,
      digit: 1,
      description: 'OKRs you need to evaluate',
    },
    {
      id: '6',
      plus: true,
      digit: 1,
      description: 'OKRs without KRs',
    },
    {
      id: '7',
      plus: true,
      digit: 1,
      description: 'Open OKRs',
    },
  ];
  empwookrs = [
    {
      id: 1,
      name: 'Fabio Fedel',
      image: '../../../../assets/images/amelia_icon.jpg',
      okr: [
        {
          id: 11,
          okrName: 'Objective Cycle 2021',
          dueDate: '1 week left',
          nudge: true,
          assignObjective: true,
        },
        {
          id: 12,
          okrName: 'Objective Cycle 2020',
          dueDate: '1 month left',
          nudge: true,
          assignObjective: true,
        },
      ],
    },
    {
      id: 2,
      name: 'Fabio Fedel',
      image: '../../../../assets/images/amelia_icon.jpg',
      okr: [
        {
          id: 21,
          okrName: 'Objective Cycle 2021',
          dueDate: '1 week left',
          nudge: true,
          assignObjective: true,
        },
      ],
    },
  ];
  okrwait = [
    {
      id: 1,
      name: 'Objective Cycle 2021',
      okr: [
        {
          id: 11,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          dueDate: '1 week left',
          assignObjective: true,
        },
        {
          id: 12,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          dueDate: '1 month left',
          assignObjective: true,
        },
      ],
    },
    {
      id: 2,
      name: 'Objective Cycle 2021',
      okr: [
        {
          id: 21,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          dueDate: '1 month left',
          assignObjective: true,
        },
      ],
    },
  ];
  unweightokr = [
    {
      id: 1,
      name: 'Objective Cycle 2021',
      okr: [
        {
          id: 11,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          dueDate: '1 week left',
          weight: '',
        },
        {
          id: 12,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          dueDate: '1 month left',
          weight: '',
        },
      ],
    },
    {
      id: 2,
      name: 'Objective Cycle 2021',
      okr: [
        {
          id: 21,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          dueDate: '1 month left',
          weight: '',
        },
      ],
    },
  ];
  okrWithoutLinks = [
    {
      cycleName: 'Objective Cycle 2021',
      okr: [
        {
          title: 'Increase Sales Closing Rate by 15%',
          name: 'Fabio Fedel',
          nudge: true,
          okrUpLinks: true,
          id: '111',
        },
        {
          title: 'Increase Sales Closing Rate by 15%',
          name: 'Fabio Fedel',
          nudge: true,
          okrUpLinks: true,
          id: '222',
        },
      ],
    },
    {
      cycleName: 'Objective Cycle 2021',
      okr: [
        {
          title: 'Increase Sales Closing Rate by 15%',
          name: 'Fabio Fedel',
          nudge: true,
          okrUpLinks: true,
          id: '333',
        },
      ],
    },
  ];

  actionneed = [
    {
      id: 1,
      name: 'Objective Cycle 2021',
      okr: [
        {
          id: 11,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          dueDate: '1 week left',
          evaluation: 0,
        },
        {
          id: 12,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          dueDate: '1 month left',
          evaluation: 0,
        },
      ],
    },
    {
      id: 2,
      name: 'Objective Cycle 2021',
      okr: [
        {
          id: 21,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          dueDate: '1 month left',
          evaluation: 0,
        },
      ],
    },
  ];

  actionneed1 = [
    {
      id: 1,
      name: 'Objective Cycle 2021',
      okr: [
        {
          id: 11,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          dueDate: '1 week left',
          nudge: true,
          addKRs: true,
          krData: '',
          measureType: '',
          start: 0,
          target: 0,
          measureName: '%',
        },
        {
          id: 12,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          dueDate: '1 month left',
          nudge: true,
          addKRs: true,
          krData: '',
          measureType: '',
          start: 0,
          target: 0,
          measureName: '%',
        },
      ],
    },
    {
      id: 2,
      name: 'Objective Cycle 2021',
      okr: [
        {
          id: 21,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          dueDate: '1 week left',
          nudge: true,
          addKRs: true,
          krData: '',
          measureType: '',
          start: 0,
          target: 0,
          measureName: '%',
        },
      ],
    },
  ];

  actionneed2 = [
    {
      id: 1,
      name: 'Objective Cycle 2021',
      okr: [
        {
          id: 11,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          approvalTime: '1 week left',
          dueDate: '1 week left',
          nudge: true,
        },
        {
          id: 12,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          approvalTime: '1 month left',
          dueDate: '1 month left',
          nudge: true,
        },
      ],
    },
    {
      id: 2,
      name: 'Objective Cycle 2021',
      okr: [
        {
          id: 21,
          okrName: {
            title: 'Increase Sales Closing Rate by 15%',
            name: 'Fabio Fedel',
          },
          approvalTime: '1 week left',
          dueDate: '1 week left',
          nudge: true,
        },
      ],
    },
  ];
  skillrate = [
    { value: 0, label: 'Unsatisfactory' },
    { value: 0, label: 'Improvement needed' },
    { value: 0, label: 'Meets expectations' },
    { value: 0, label: 'Exceeds expectations' },
    { value: 0, label: 'Exceptional' },
  ];

  filterTypeArr: any = [
    {
      label: this.coreService.getTranslation(
        'MANAGER_ENTRYPOINT.DETAILS.O5.Section6.OptionMeasure1'
      ),
      value: '0',
    },
    {
      label: this.coreService.getTranslation(
        'MANAGER_ENTRYPOINT.DETAILS.O5.Section6.OptionMeasure2'
      ),
      value: '1',
    },
    {
      label: this.coreService.getTranslation(
        'MANAGER_ENTRYPOINT.DETAILS.O5.Section6.OptionMeasure3'
      ),
      value: '2',
    },
  ];
  okrHintsData: any = [
    {
      okrtitle: 'Increase Sales Closing Rate by 15%',
      userName: 'Fabio Fedel',
      okrData: [
        {
          key_Result: 'Increase Sales',
          measure_Start_Target: true,
          measureType: false,
          measure_name: '%',
          date: 'dd/mm/yyyyy',
        },
        {
          key_Result: 'Increase Sales',
          measure_Start_Target: false,
          measureType: true,
          measure_name: 'employee',
          date: 'dd/mm/yyyyy',
        },
      ],
    },
    {
      okrtitle: 'Increase Sales Closing Rate by 15%',
      userName: 'Fabio Fedel',
      okrData: [
        {
          key_Result: 'Increase Sales',
          measure_Start_Target: true,
          measureType: false,
          measure_name: '%',
          date: 'dd/mm/yyyyy',
        },
      ],
    },
    {
      okrtitle: 'Increase Sales Closing Rate by 15%',
      userName: 'Angelo Vasseri',
      okrData: [
        {
          key_Result: 'Increase Sales',
          measure_Start_Target: true,
          measureType: false,
          measure_name: '%',
          date: 'dd/mm/yyyyy',
        },
      ],
    },
    {
      okrtitle: 'Increase Sales Closing Rate by 15%',
      userName: 'Angelo Vasseri',
      okrData: [
        {
          key_Result: 'Increase Sales',
          measure_Start_Target: true,
          measureType: false,
          measure_name: '%',
          date: 'dd/mm/yyyyy',
        },
      ],
    },
  ];
  selected = {
    label: this.coreService.getTranslation(
      'MANAGER_ENTRYPOINT.DETAILS.O5.Section6.OptionMeasure1'
    ),
    value: '0',
  };

  showHide: boolean = false;
  popupValue: boolean;
  selectedData: any;
  infoData: any;
  infoData2: {
    id: number;
    name: string;
    image: string;
    okr: {
      id: number;
      okrName: string;
      dueDate: string;
      nudge: boolean;
      assignObjective: boolean;
    }[];
  }[];
  showTable: boolean;
  extra2Id: any;
  selectedRole: any;
  openId: any = 'd';
  sortDir: number;
  @ViewChild('textarea') textarea: ElementRef;
  content: string = '';
  manager: any;
  private searchInput: ElementRef;
  currentCycle: any;
  topPosToStartShowing = 0;
  scrollPosition: any;
  currentState: string;
  userInfo: any;
  isCompanyAdmin: boolean;
  measureName: any;
  target: any;
  start: any;
  measure: any;
  krName: any;
  krData: any;
  reloadAct: boolean = true;
  isBinary: boolean;
  setWidth: number;
  maxWidth: number;
  okrClassWidth: number;
  empClassWidth: number;
  reOpen: any;
  oldData: any;
  @ViewChild('searchInput', { static: false }) set _searchInput(
    content: ElementRef
  ) {
    if (content) {
      // initially setter gets called with undefined
      this.searchInput = content;
    }
  }
  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    public router: Router,
    private srv: CycleManagerService,
    private companyReportService: CompanyReportService,
    public coreService: CoreService,
    public pp360SpinnerService: Pp360SpinnerService,
    public cyclesUtilitiesService: CyclesUtilitiesService
  ) {}

  ngOnInit(): void {
    this.userInfo = this.coreService.getUserInfo();
    this.data = this.response;
    if (this.reOpen) {
      for (let i of this.data.Rows) {
        if (i.TitleKey == 'O91_TITLE') {
          this.openTable(i);
        }
      }
    }
    // this.data['EnableWheigthing']=true;
    for (let cycle of this.data?.Rows) {
      if (cycle.CycleId) {
        this.cycle = this.companyReportService
          .getMyCycleDetails(cycle.CycleId)
          .subscribe((myCycleDetails: any) => {
            // console.log('myCycleDetails', myCycleDetails);
            this.currentCycle = myCycleDetails;
            // if(this.currentCycle.EnableWheigthing == false){
            //   this.data['EnableWheigthing']=false;
            // }
          });
      }
    }

    if (this.data.Key === 'O3') {
      for (let item of this.data.Rows) {
        item.Rows.sort((a, b) => {
          a = a.LastUpdate;
          b = b.LastUpdate;
          return (
            String(b).localeCompare(String(a), 'en', { numeric: true }) * -1
          );
        });
      }
    }

    if (this.data.Key === 'O2') {
      for (let item of this.data.Rows) {
        item.Rows.sort(
          (a, b) =>
            a.PercentVsExpected - b.PercentVsExpected || a.TimeLeft - b.TimeLeft
        );
      }
    }

    if (this.data.Key == 'O1') {
      this.cycleData = this.data.Rows;
      console.log(this.cycleData);

      if (this.cycleData) {
        this.cycleData.forEach((ele: any) => {
          ele.TimeLeftUpdated = this.timeLeft(ele.TimeLeft);
        });
      }
    }
    if (this.data.Key == 'O2') {
      this.infoData = this.data.Rows;
      if (this.infoData) {
        for (let info of this.infoData) {
          info.Rows.forEach((ele: any) => {
            ele.TimeLeftUpdated = this.timeLeft(ele.TimeLeft);
            ele['textValue'] = this.coreService.getTranslation(
              'MANAGER_ENTRYPOINT.DETAILS.O2.02_SuggestedComment'
            );
            let name = " '" + ele.Name + "' ";
            ele['textValue'] = ele['textValue'].replace('{{OKRName}}', name);
            ele['textValue'] = ele['textValue'].replace(
              '{{ProgressPercentage}}',
              ele.Progress + '%'
            );
            ele['textValue'] = ele['textValue'].replace(
              '{{ProgressExpected}}',
              ele.PercentVsExpected + '%'
            );
            // ele["textValue"]= "Are you having problems with the OKR '"+ ele.Name +"'? Its current progress is at 10% while the expected one is 60%";
          });
        }
      }
    }
    if (this.data.Key == 'O3') {
      this.infoData = this.data.Rows;
      if (this.infoData) {
        for (let info of this.infoData) {
          info.Rows.forEach((ele: any) => {
            ele.TimeLeftUpdated = this.timeLeft(ele.TimeLeft);
            ele['textValue'] = this.coreService.getTranslation(
              'MANAGER_ENTRYPOINT.DETAILS.O3.SuggestedComment'
            );
            let name = " '" + ele.Name + "' ";
            ele['textValue'] = ele['textValue'].replace('{{OKRName}}', name);
            ele['textValue'] = ele['textValue'].replace(
              '{{TimePassed}}',
              ele.TimeLeftUpdated
            );
            //              ele["textValue"]= "Time for update! Your OKR '"+ ele.Name +`' hasn’t been updated for 10 days/weeks/months.
            // Remember, keep your OKRs up to date is key!`;
          });
          // console.log(this.infoData);
        }
      }
    }
    if (this.data.Key == 'O4') {
      this.infoData = this.data.Rows;
      if (this.infoData) {
        for (let info of this.infoData) {
          if (info.Rows.length > 10) {
            info.Rows.splice(10);
          }
          info.Rows.sort((a, b) => {
            return a.TimeLeft > b.TimeLeft
              ? 1
              : a.TimeLeft < b.TimeLeft
                ? -1
                : 0;
          });
          info.Rows.forEach((ele: any) => {
            ele.TimeLeftUpdated = this.timeLeftDays(ele.TimeLeft);
            ele['textValue'] = this.coreService.getTranslation(
              'MANAGER_ENTRYPOINT.DETAILS.O4.SuggestedComment'
            );
            let name = " '" + ele.Name + "' ";
            ele['textValue'] = ele['textValue'].replace('{{OKRName}}', name);
            ele['textValue'] = ele['textValue'].replace(
              '{{ProgressPercentage}}',
              ele.Progress + '%'
            );
            ele['textValue'] = ele['textValue'].replace(
              '{{ProgressExpected}}',
              ele.PercentVsExpected + '%'
            );
            // ele["textValue"]= "Are you having problems with the OKR '"+ ele.Name +"'? Its current progress is at 10% while the expected one is 60%";
          });
        }
      }
      console.log(this.infoData);
    }
    if (this.data.Key === 'O5') {
      this.infoData = this.data.Rows;
      console.log(this.infoData);

      if (this.infoData) {
        for (let i of this.infoData) {
          i.Rows.sort((a, b) => {
            return a.Rows.length > b.Rows.length
              ? -1
              : a.Rows.length < b.Rows.length
                ? 1
                : 0;
          });
        }
      }

      this.infoData.sort((a, b) => {
        return a.Rows.length > b.Rows.length
          ? -1
          : a.Rows.length < b.Rows.length
            ? 1
            : 0;
      });
    }
    if (this.data.Key === 'O6') {
      this.infoData = this.data.Rows;
      if (this.infoData) {
        for (let i of this.infoData) {
          i.Rows.forEach((j: any) => {
            i.Rows.sort((a, b) => {
              if (a.AtRiskOKRs === b.AtRiskOKRs) {
                return a.Progress > b.Progress
                  ? 1
                  : a.Progress < b.Progress
                    ? -1
                    : 0;
              }
              return a.AtRiskOKRs > b.AtRiskOKRs
                ? -1
                : a.AtRiskOKRs < b.AtRiskOKRs
                  ? 1
                  : 0;
            });
            j.OKRs.forEach((ele: any) => {
              j.OKRs.sort((a, b) => {
                return a.Progress > b.Progress
                  ? 1
                  : a.Progress < b.Progress
                    ? -1
                    : 0;
              });
            });
          });
          this.infoData.sort((a, b) => {
            return a.Rows.length > b.Rows.length
              ? -1
              : a.Rows.length < b.Rows.length
                ? 1
                : 0;
          });
          // console.log("o6",this.infoData);
        }
        for (let info of this.infoData) {
          for (let i of info.Rows) {
            if (i.length == 1) {
              info['okrLength'] = true;
            }
            i.OKRs.forEach((ele: any) => {
              ele.TimeLeftUpdated = this.timeLeft(ele.TimeLeft);
              ele['textValue'] = this.coreService.getTranslation(
                'MANAGER_ENTRYPOINT.DETAILS.O4.SuggestedComment'
              );
              let name = " '" + ele.Name + "' ";
              ele['textValue'] = ele['textValue'].replace('{{OKRName}}', name);
              ele['textValue'] = ele['textValue'].replace(
                '{{ProgressPercentage}}',
                ele.Progress + '%'
              );
              ele['textValue'] = ele['textValue'].replace(
                '{{ProgressExpected}}',
                ele.Expected + '%'
              );
            });
          }
          // setTimeout(() => {
          //   console.log($(".row-5").innerWidth());
          // });
        }
      }
    }
    if (this.data.Key === 'O9') {
      this.okr5 = this.data.Rows;
      console.log(this.okr5);
      this.okr5 = this.okr5.filter(
        (x: any) => x.Value > 0 && x.Rows.length > 0
      );
    }
    if (this.data.Key === 'O7' || this.data.Key === 'O8') {
      this.infoData = this.data.Rows;
      this.infoData.sort((a, b) => {
        return a.Rows.length > b.Rows.length
          ? -1
          : a.Rows.length < b.Rows.length
            ? 1
            : 0;
      });

      for (let info of this.infoData) {
        for (let i of info.Rows) {
          if (i.length == 1) {
            info['okrLength'] = true;
          }
          i.OKRs.forEach((ele: any) => {
            // ele.InclinationValue=3;
            if (this.data.Key === 'O7') {
              if (ele.InclinationValue <= 0) {
                ele.InclinationValue = 0;
              } else {
                ele['label'] = this.skillrate[ele.InclinationValue - 1].label;
              }
              if (ele.InclinationValue > 0) {
                let arr = [];
                for (let j = 0; j < ele.InclinationValue; j++) {
                  arr.push(1);
                }
                for (let j = 0; j < 5 - ele.InclinationValue; j++) {
                  arr.push(0);
                }
                ele['amount'] = arr;
              } else {
                ele['amount'] = [0, 0, 0, 0, 0];
              }
            }

            if (this.data.Key === 'O8') {
              if (ele.ChallengeValue <= 0) {
                ele.ChallengeValue = 0;
              } else {
                ele['label'] = this.skillrate[ele.ChallengeValue - 1].label;
              }
              if (ele.ChallengeValue > 0) {
                let arr = [];
                for (let j = 0; j < ele.ChallengeValue; j++) {
                  arr.push(1);
                }
                for (let j = 0; j < 5 - ele.ChallengeValue; j++) {
                  arr.push(0);
                }
                ele['amount'] = arr;
              } else {
                ele['amount'] = [0, 0, 0, 0, 0];
              }
            }

            if (ele.TimeLeft < 0) {
              ele.TimeLeft = Math.abs(ele.TimeLeft);
            }
            ele.TimeLeftUpdated = this.timeLeft(ele.TimeLeft);
            if (this.data.Key === 'O7') {
              ele['textValue'] = this.coreService.getTranslation(
                'MANAGER_ENTRYPOINT.DETAILS.O5.O8.SuggestedComment'
              );
            }
            if (this.data.Key === 'O8') {
              ele['textValue'] = this.coreService.getTranslation(
                'MANAGER_ENTRYPOINT.DETAILS.O5.O9.SuggestedComment'
              );
            }
            let name = " '" + ele.Name + "' ";
            ele['textValue'] = ele['textValue'].replace('{{OKRName}}', name);
          });
          if (this.data.Key === 'O7') {
            i['sumOfValue'] = i.OKRs.reduce((accumulator, object) => {
              return accumulator + object.InclinationValue;
            }, 0);
          }
          if (this.data.Key === 'O8') {
            i['sumOfValue'] = i.OKRs.reduce((accumulator, object) => {
              return accumulator + object.ChallengeValue;
            }, 0);
          }
        }
        this.infoData.sort((a, b) => {
          return a.Rows.length > b.Rows.length
            ? -1
            : a.Rows.length < b.Rows.length
              ? 1
              : 0;
        });
      }

      for (let i of this.infoData) {
        let dat;
        dat = i.Rows;
        if (this.data.Value <= 0) {
          dat.sort((a, b) => {
            return a.Employee.Name.toLowerCase().trim() >
              b.Employee.Name.toLowerCase().trim()
              ? 1
              : a.Employee.Name.toLowerCase().trim() <
                  b.Employee.Name.toLowerCase().trim()
                ? -1
                : 0;
          });
        } else {
          dat.sort((a, b) => {
            return a.sumOfValue > b.sumOfValue
              ? -1
              : a.sumOfValue < b.sumOfValue
                ? 1
                : 0;
          });
          for (let i of dat) {
            if (this.data.Key === 'O7') {
              i.OKRs.sort((a, b) => {
                return a.InclinationValue > b.InclinationValue
                  ? -1
                  : a.InclinationValue < b.InclinationValue
                    ? 1
                    : 0;
              });
            }
            if (this.data.Key === 'O8') {
              i.OKRs.sort((a, b) => {
                return a.ChallengeValue > b.ChallengeValue
                  ? -1
                  : a.ChallengeValue < b.ChallengeValue
                    ? 1
                    : 0;
              });
            }
          }
        }
      }
    }
    setTimeout(() => {
      // console.log($(".empClass").innerWidth());
      let b: any = document.getElementsByClassName('empClass');
      let c: any = document.getElementsByClassName('okrClass');
      let arr = [],
        arr1 = [];
      for (let i of b) {
        arr.push(i.clientWidth);
      }
      for (let i of c) {
        arr1.push(i.clientWidth);
      }
      this.empClassWidth = Math.max(...arr) - 30;
      this.okrClassWidth = Math.max(...arr1) - 25;
      // console.log(this.empClassWidth, this.okrClassWidth);
    });
  }
  sortArr(colName, table, event) {
    let target = event.currentTarget;
    let classList = target.classList;
    if (classList.contains('up')) {
      classList.remove('up');
      classList.add('down');
      this.sortDir = -1;
    } else {
      classList.add('up');
      classList.remove('down');
      this.sortDir = 1;
    }

    table.sort((a, b) => {
      if (typeof a[colName] == 'string') {
        a = a[colName].toLowerCase();
        b = b[colName].toLowerCase();
        return a.localeCompare(b) * this.sortDir;
      } else {
        a = a[colName];
        b = b[colName];
        return (
          String(a).localeCompare(String(b), 'en', { numeric: true }) *
          this.sortDir
        );
      }
    });
  }

  sort(event: any, type: string, subtype: string = '', arr: any = []) {
    let currentSort;
    if (event) {
      if ($(event.target).hasClass('sort-asc')) {
        $(event.target).removeClass('sort-asc'),
          $(event.target).addClass('sort-desc');
        currentSort = 'desc';
      } else {
        $(event.target).removeClass('sort-desc'),
          $(event.target).addClass('sort-asc');
        currentSort = 'asc';
      }
    } else {
      currentSort = 'desc';
    }

    if (subtype == 'single') {
      arr.sort((a, b) => {
        // console.log(a, type, subtype);

        switch (type) {
          case 'Name':
            if (!a || !a.Employee.Name || !b || !a.Employee.Name) return -1;
            if (currentSort === 'desc') {
              return a.Employee.Name.toLowerCase().trim() >
                b.Employee.Name.toLowerCase().trim()
                ? -1
                : a.Employee.Name.toLowerCase().trim() <
                    b.Employee.Name.toLowerCase().trim()
                  ? 1
                  : 0;
            } else {
              return a.Employee.Name.toLowerCase().trim() >
                b.Employee.Name.toLowerCase().trim()
                ? 1
                : a.Employee.Name.toLowerCase().trim() <
                    b.Employee.Name.toLowerCase().trim()
                  ? -1
                  : 0;
            }
            break;

          case 'EmpLength':
            if (!a || !a.EmpLength || !b || !a.EmpLength) return -1;
            if (currentSort === 'desc') {
              return a.EmpLength > b.EmpLength
                ? -1
                : a.EmpLength < b.EmpLength
                  ? 1
                  : 0;
            } else {
              return a.EmpLength > b.EmpLength
                ? 1
                : a.EmpLength < b.EmpLength
                  ? -1
                  : 0;
            }
            break;

          default:
            if (!a || !a[type] || !b || !a[type]) return -1;
            if (currentSort === 'desc') {
              return a[type] > b[type] ? -1 : a[type] < b[type] ? 1 : 0;
            } else {
              return a[type] > b[type] ? 1 : a[type] < b[type] ? -1 : 0;
            }

            break;
        }
      });
    }

    if (subtype == 'O5') {
      if (type == 'OKRName') {
        arr.sort((a, b) => {
          if (!a || !a[type] || !b || !a[type]) return -1;
          if (currentSort === 'desc') {
            return a[type].toLowerCase().trim() > b[type].toLowerCase().trim()
              ? -1
              : a[type].toLowerCase().trim() < b[type].toLowerCase().trim()
                ? 1
                : 0;
          } else {
            return a[type].toLowerCase().trim() > b[type].toLowerCase().trim()
              ? 1
              : a[type].toLowerCase().trim() < b[type].toLowerCase().trim()
                ? -1
                : 0;
          }
        });
      } else if (type == 'MeasureType') {
        for (let i of arr) {
          i.Rows.sort((a, b) => {
            if (!a || !a[type] || !b || !a[type]) return -1;
            if (currentSort === 'desc') {
              return a[type] > b[type] ? -1 : a[type] < b[type] ? 1 : 0;
            } else {
              return a[type] > b[type] ? 1 : a[type] < b[type] ? -1 : 0;
            }
          });
        }
      } else {
        for (let i of arr) {
          i.Rows.sort((a, b) => {
            if (!a || !a[type] || !b || !a[type]) return -1;
            if (currentSort === 'desc') {
              return a[type].toLowerCase().trim() > b[type].toLowerCase().trim()
                ? -1
                : a[type].toLowerCase().trim() < b[type].toLowerCase().trim()
                  ? 1
                  : 0;
            } else {
              return a[type].toLowerCase().trim() > b[type].toLowerCase().trim()
                ? 1
                : a[type].toLowerCase().trim() < b[type].toLowerCase().trim()
                  ? -1
                  : 0;
            }
          });
        }
      }
    }

    if (subtype == 'double') {
      for (let i of arr) {
        i.OKRs.sort((a, b) => {
          switch (type) {
            case 'Name':
              if (!a || !a.Name || !b || !a.Name) return -1;
              if (currentSort === 'desc') {
                return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
                  ? -1
                  : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
                    ? 1
                    : 0;
              } else {
                return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
                  ? 1
                  : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
                    ? -1
                    : 0;
              }
              break;
            case 'OKRName':
              if (!a || !a.OKRName || !b || !a.OKRName) return -1;
              if (currentSort === 'desc') {
                return a.OKRName.toLowerCase().trim() >
                  b.OKRName.toLowerCase().trim()
                  ? -1
                  : a.OKRName.toLowerCase().trim() <
                      b.OKRName.toLowerCase().trim()
                    ? 1
                    : 0;
              } else {
                return a.OKRName.toLowerCase().trim() >
                  b.OKRName.toLowerCase().trim()
                  ? 1
                  : a.OKRName.toLowerCase().trim() <
                      b.OKRName.toLowerCase().trim()
                    ? -1
                    : 0;
              }
              break;
            default:
              if (a[type] !== b[type]) {
                if (!a || !a[type] || !b || !a[type]) return -1;
                if (currentSort === 'desc') {
                  return a[type] > b[type] ? -1 : a[type] < b[type] ? 1 : 0;
                } else {
                  return a[type] > b[type] ? 1 : a[type] < b[type] ? -1 : 0;
                }
              }

              break;
          }
        });
      }
    }

    if (subtype == 'custom') {
      if (type == 'first') {
        arr.sort((a, b) => {
          if (!a || !a.Employee.Name || !b || !a.Employee.Name) return -1;
          if (currentSort === 'desc') {
            return a.Employee.Name.toLowerCase().trim() >
              b.Employee.Name.toLowerCase().trim()
              ? -1
              : a.Employee.Name.toLowerCase().trim() <
                  b.Employee.Name.toLowerCase().trim()
                ? 1
                : 0;
          } else {
            return a.Employee.Name.toLowerCase().trim() >
              b.Employee.Name.toLowerCase().trim()
              ? 1
              : a.Employee.Name.toLowerCase().trim() <
                  b.Employee.Name.toLowerCase().trim()
                ? -1
                : 0;
          }
        });
      } else {
        for (let i of arr) {
          i.Cycles.sort((a, b) => {
            switch (type) {
              case 'CycleName':
                if (!a || !a.CycleName || !b || !a.CycleName) return -1;
                if (currentSort === 'desc') {
                  return a.CycleName.toLowerCase().trim() >
                    b.CycleName.toLowerCase().trim()
                    ? -1
                    : a.CycleName.toLowerCase().trim() <
                        b.CycleName.toLowerCase().trim()
                      ? 1
                      : 0;
                } else {
                  return a.CycleName.toLowerCase().trim() >
                    b.CycleName.toLowerCase().trim()
                    ? 1
                    : a.CycleName.toLowerCase().trim() <
                        b.CycleName.toLowerCase().trim()
                      ? -1
                      : 0;
                }
                break;

              default:
                if (!a || !a[type] || !b || !a[type]) return -1;
                if (currentSort === 'desc') {
                  return a[type] > b[type] ? -1 : a[type] < b[type] ? 1 : 0;
                } else {
                  return a[type] > b[type] ? 1 : a[type] < b[type] ? -1 : 0;
                }

                break;
            }
          });
        }
      }
    }
  }

  adjustTextareaHeight() {
    const wordToHighlight = 'specific';
    const highlightedContent = this.content.replace(
      new RegExp(`(${wordToHighlight})`, 'gi'),
      '<strong>$1</strong>'
    );
    this.content = highlightedContent;

    this.description = this.content;
    const textareaElement: HTMLTextAreaElement = this.textarea.nativeElement;
    textareaElement.style.height = 'auto';
    textareaElement.style.height = textareaElement.scrollHeight + 'px';
  }
  openTable(data) {
    this.showTable = true;
    // console.log(data);

    this.selectedData = data.TitleKey;
    if (this.selectedData === 'O91_TITLE') {
      this.oldData = data;
      this.empwookrs = data.Rows;
      this.infoData = this.empwookrs;
      // console.log(this.infoData);
      for (let info of this.infoData) {
        info.Cycles.forEach((ele: any) => {
          // console.log(ele);
          ele['DaysLeftToDefinitions'] = this.timeLeft(
            ele.DaysLeftToDefinition
          );
          ele['textValue'] = this.coreService.getTranslation(
            'MANAGER_ENTRYPOINT.DETAILS.O5.Section1.SuggestedComment'
          );
          // ele["textValue"]=ele["textValue"].replace('{{CycleName}}',ele.CycleName.toUpperCase());
          let name = " '" + ele.CycleName.toUpperCase() + "' ";
          ele['textValue'] = ele['textValue'].replace('{{CycleName}}', name);
        });

        // console.log(this.infoData);
      }
      this.infoData.sort((a, b) => {
        return a.Cycles.length > b.Cycles.length
          ? -1
          : a.Cycles.length < b.Cycles.length
            ? 1
            : 0;
      });
    }
    if (this.selectedData === 'O92_TITLE') {
      this.okrwait = data.Rows;
      this.infoData = this.okrwait;
      // console.log(this.infoData);

      for (let info of this.infoData) {
        // console.log(info);
        info.OKRs.forEach((ele: any) => {
          ele['DaysLefts'] = this.timeLeft(ele.DaysLeft);
        });
      }
      this.infoData.sort((a, b) => {
        return a.OKRs.length > b.OKRs.length
          ? -1
          : a.OKRs.length < b.OKRs.length
            ? 1
            : 0;
      });
    }
    if (this.selectedData == 'O93_TITLE') {
      this.unweightokr = data.Rows;
      if (data.Value === 0) {
        this.isNoItemAvailable = true;
      }
      console.log(this.isNoItemAvailable);

      this.infoData = this.unweightokr;
      for (let info of this.infoData) {
        // console.log(info);
        info.OKRs.forEach((ele: any) => {
          ele['DaysLefts'] = this.timeLeft(ele.DaysLeft);
        });
      }
    }
    if (this.selectedData === 'O94_TITLE') {
      this.okrWithoutLinks = data.Rows;
      this.infoData = this.okrWithoutLinks;
      // console.log(this.infoData);
      for (let info of this.infoData) {
        // console.log(info);
        info.OKRs.forEach((ele: any) => {
          // ele.DaysLeft = this.timeLeft(ele.DaysLeft);
          ele['textValue'] = this.coreService.getTranslation(
            'MANAGER_ENTRYPOINT.DETAILS.O5.Section4.SuggestedComment'
          );
          let name = " '" + ele.OKRName.toUpperCase() + "' ";
          ele['textValue'] = ele['textValue'].replace('{{OKRName}}', name);
          // ele["textValue"] = "Time to create links! Are you having trouble defining Up links for this Objetive " + ele.OKRName + "?"
        });
      }
      this.infoData.sort((a, b) => {
        return a.OKRs.length > b.OKRs.length
          ? -1
          : a.OKRs.length < b.OKRs.length
            ? 1
            : 0;
      });
    }
    if (this.selectedData === 'O95_TITLE') {
      this.actionneed = data.Rows;
      this.infoData = this.actionneed;
      // console.log(this.infoData);
      for (let z of this.infoData) {
        for (let i of z.OKRs) {
          i['DaysLefts'] = this.timeLeft(i.DaysLeft);
          let arr = [];
          for (let j = 0; j < i.evaluation; j++) {
            arr.push(1);
          }
          for (let j = 0; j < 5 - i.evaluation; j++) {
            arr.push(0);
          }
          i['amount'] = arr;
        }
      }
    }
    if (this.selectedData === 'O96_TITLE') {
      this.actionneed1 = data.Rows;
      this.infoData = this.actionneed1;
      // console.log(this.infoData);
      for (let info of this.infoData) {
        // console.log(info);
        info.OKRs.forEach((ele: any) => {
          ele['DaysLefts'] = this.timeLeft(ele.DaysLeft);
          ele['textValue'] = this.coreService.getTranslation(
            'MANAGER_ENTRYPOINT.DETAILS.O5.Section6.SuggestedComment'
          );
          let name = " '" + ele.OKRName.toUpperCase() + "' ";
          ele['textValue'] = ele['textValue'].replace('{{OKRName}}', name);
        });
      }
      this.infoData.sort((a, b) => {
        return a.OKRs.length > b.OKRs.length
          ? -1
          : a.OKRs.length < b.OKRs.length
            ? 1
            : 0;
      });

      setTimeout(() => {
        this.setWidth =
          $('.row-5').innerWidth() +
          $('.row-9').innerWidth() +
          $('.row-10').innerWidth() +
          $('.row-12').innerWidth();
      });
    }
    if (this.selectedData === 'O97_TITLE') {
      this.actionneed2 = data.Rows;
      this.infoData = this.actionneed2;
      // console.log(this.infoData);
      for (let info of this.infoData) {
        // console.log(info);
        info.OKRs.forEach((ele: any) => {
          ele['DaysLefts'] = this.timeLeft(ele.DaysLeft);
          ele['DaysLeftsToApproval'] = this.timeLeft(ele.DaysLeftToApproval);
          ele['textValue'] = this.coreService.getTranslation(
            'MANAGER_ENTRYPOINT.DETAILS.O5.Section7.SuggestedComment'
          );
          let name = " '" + ele.OKRName.toUpperCase() + "' ";
          ele['textValue'] = ele['textValue'].replace('{{OKRName}}', name);
        });
      }
      this.infoData.sort((a, b) => {
        return a.OKRs.length > b.OKRs.length
          ? -1
          : a.OKRs.length < b.OKRs.length
            ? 1
            : 0;
      });
    }
  }
  closeTable(item) {
    this.showTable = false;
  }
  eval(i, item, infoData) {
    item.evaluation = i + 1;
    for (let z of infoData) {
      for (let i of z.OKRs) {
        if (i.$id == item.$id) {
          let arr = [];
          for (let j = 0; j < i.evaluation; j++) {
            arr.push(1);
          }
          for (let j = 0; j < 5 - i.evaluation; j++) {
            arr.push(0);
          }
          i['amount'] = arr;
        }
      }
    }
  }
  onPopupClose() {
    if (this.popupValue) {
      this.popupValue = false;
    }
    for (let i of this.okrData) {
      i['popupOpen'] = false;
    }
  }
  onPopupCloses(table) {
    if (this.popupValue) {
      this.popupValue = false;
    }

    if (table) {
      for (let i of table) {
        if (i.OKRs) {
          for (let z of i.OKRs) {
            z.popupOpen = false;
          }
        } else if (i.Cycles) {
          for (let z of i.Cycles) {
            z.popupOpen = false;
          }
        } else if (i.Rows) {
          for (let j of i.Rows) {
            if (j.OKRs) {
              for (let a of j.OKRs) {
                // console.log(a);

                a.popupOpen = false;
              }
            } else {
              j.popupOpen = false;
            }
          }
        }
        i['popupOpen'] = false;
      }
    } else {
      for (let i of this.okrData) {
        i['popupOpen'] = false;
      }
    }
  }
  blurfield(i) {
    if (i.weight) {
      i['error'] = true;
    }
  }

  saveWeight(row, objective: any) {
    console.log(row);
    console.log(objective);

    this.srv.setObjectiveWeight(objective).subscribe((r) => {
      //Change weighh in original Bound object
      let emp = this.filteredEmployees.find(
        (x) => x.Employee.Id == row.Employee.Id
      );
      if (emp) {
        let obj = emp.Objectives.List.find((x) => x.Id == objective.Id);
        if (obj) {
          obj.Weight = objective.Weight;
        }
        emp.showWeightTot = this.showWeightTot(emp);
      }

      emp = this.originalFilteredEmployees.find(
        (x) => x.Employee.Id == row.Employee.Id
      );
      if (emp) {
        let obj = emp.Objectives.List.find((x) => x.Id == objective.Id);
        if (obj) {
          obj.Weight = objective.Weight;
        }
        emp.showWeightTot = this.showWeightTot(emp);
      }
    });
  }
  showWeightTot(row: any) {
    let weightTot;
    if (this.cycle.EnableWheigthing) {
      let itemWeights = row.Objectives.List.filter(
        (item: any) => item.Visibility !== 'Private' && item.Weight
      );
      weightTot = 0;
      itemWeights.forEach((w: any) => (weightTot += Number(w.Weight)));
    }
    return weightTot;
  }
  focusfield(i) {
    i['error'] = false;
  }
  nudgePopupInside(item, table) {
    this.popupValue = true;
    for (let j of table) {
      if (j.OKRs) {
        for (let i of j.OKRs) {
          if (i.$id === item.$id) {
            i['popupOpen'] = true;
          } else {
            i['popupOpen'] = false;
          }
          if (i['popupOpen'] === true && this.popupValue) {
          }
        }
      } else if (j.Cycles) {
        for (let i of j.Cycles) {
          if (i.$id === item.$id) {
            i['popupOpen'] = true;
          } else {
            i['popupOpen'] = false;
          }
          if (i['popupOpen'] === true && this.popupValue) {
          }
        }
      }
    }
  }

  nudgePopup(item) {
    // console.log(item);
    this.popupValue = true;
    item['popupOpen'] = !item['popupOpen'];
    if (item['popupOpen'] === true && this.popupValue) {
    }
  }

  openNudge(item) {
    this.bsModalRef = this.modalService.show(ManagerOkrPopupComponent, {
      class: 'okrPopUp ammazza wallPopup',
      ignoreBackdropClick: false,
      initialState: {
        response: item,
        type: item.type,
      },
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.showHide = false;
  }
  openOkrFilter(item) {
    for (let ele of this.infoData) {
      for (let i of ele.OKRs) {
        if (i.$id === item.$id) {
          i['isOkrFilter'] = true;
        } else {
          i['isOkrFilter'] = false;
        }
      }
    }
  }
  onSelectedEmployee(event) {
    const emp = event?.selected || [];
  }

  openextra1(item) {
    if (this.extra2Id == item.$id) {
      this.extra2Id = '';
    } else {
      this.extra2Id = item.$id;
    }
  }

  @ViewChild('krButton') searchElement: ElementRef;

  opentext(item) {
    // console.log(item);
    this.isBinary = false;
    this.extra2Id = '';
    this.openId = item.$id;
    item['krData'] = '';
    item['measure'] = this.filterTypeArr[0].value;
    item['start'] = '0';
    item['target'] = '0';
    item['measureName'] = '%';
    this.krData = item.krData;
    this.measure = item.measure;
    this.start = item.start ? item.start : 0;
    this.target = item.target ? item.target : 0;
    this.measureName = item.measureName ? item.measureName : '%';
    setTimeout(() => {
      // document.getElementById("krButton").focus();
      this.searchElement.nativeElement.focus();
    });
  }

  openOkrsCycle(item) {
    if (item) {
      this.router.navigate(['/Manager/cycles'], {
        queryParams: {
          cycleid: item.CycleId,
          type: 'openOKRS',
          cState: 'Definition',
        },
      });
      this.modalRef.hide();
    }
  }

  changeItemType(event) {
    this.measure = event.value;
    if (this.measure == 2) {
      this.isBinary = true;
    } else {
      this.isBinary = false;
    }
  }

  timeLeft(num: number) {
    let remain;
    if (this.userInfo.language != 'nl' && this.userInfo.language != 'zh') {
      if (num < 0) {
        // return "0 day left";
        // return {{'MANAGER_ENTRYPOINT.TIME.noDayLeft' | translate}};
        return this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.noDayLeft'
        );
      } else if (num > 30) {
        remain = Math.round(num / 30);
        if (remain > 1) {
          return (
            remain +
            ' ' +
            this.coreService.getTranslation(
              'MANAGER_ENTRYPOINT.TIME.monthsLeft'
            )
          );
        }
        return (
          remain +
          ' ' +
          this.coreService.getTranslation('MANAGER_ENTRYPOINT.TIME.monthLeft')
        );
      } else if (num < 30 && num > 7) {
        remain = Math.round(num / 7);
        if (remain > 1) {
          return (
            remain +
            ' ' +
            this.coreService.getTranslation('MANAGER_ENTRYPOINT.TIME.weeksLeft')
          );
        }
        return (
          remain +
          ' ' +
          this.coreService.getTranslation('MANAGER_ENTRYPOINT.TIME.weekLeft')
        );
      } else {
        remain = Math.round(num);
        if (remain > 1) {
          return (
            remain +
            ' ' +
            this.coreService.getTranslation('MANAGER_ENTRYPOINT.TIME.daysLeft')
          );
        }
        return (
          remain +
          ' ' +
          this.coreService.getTranslation('MANAGER_ENTRYPOINT.TIME.dayLeft')
        );
      }
    } else if (this.userInfo.language == 'nl') {
      if (num < 0) {
        return this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.noDayLeft'
        );
      } else if (num > 30) {
        remain = Math.round(num / 30);
        let a = this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.monthsLeft'
        );
        let b = this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.monthLeft'
        );
        if (remain > 1) {
          return (
            a.split(' ')[0] + ' ' + remain + ' ' + a.substr(a.indexOf(' ') + 1)
          );
        }
        return (
          b.split(' ')[0] + ' ' + remain + ' ' + b.substr(b.indexOf(' ') + 1)
        );
      } else if (num < 30 && num > 7) {
        remain = Math.round(num / 7);
        let a = this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.weeksLeft'
        );
        let b = this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.weekLeft'
        );
        if (remain > 1) {
          return (
            a.split(' ')[0] + ' ' + remain + ' ' + a.substr(a.indexOf(' ') + 1)
          );
        }
        return (
          b.split(' ')[0] + ' ' + remain + ' ' + b.substr(b.indexOf(' ') + 1)
        );
      } else {
        remain = Math.round(num);
        let a = this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.daysLeft'
        );
        let b = this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.dayLeft'
        );
        if (remain > 1) {
          return (
            a.split(' ')[0] + ' ' + remain + ' ' + a.substr(a.indexOf(' ') + 1)
          );
        }
        return (
          b.split(' ')[0] + ' ' + remain + ' ' + b.substr(b.indexOf(' ') + 1)
        );
      }
    } else if (this.userInfo.language == 'zh') {
      if (num < 0) {
        return this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.noDayLeft'
        );
      } else if (num > 30) {
        remain = Math.round(num / 30);
        let a = this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.monthsLeft'
        );
        let b = this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.monthLeft'
        );
        if (remain > 1) {
          return a.charAt(0) + a.charAt(1) + ' ' + remain + ' ' + a.substr(2);
        }
        return b.charAt(0) + b.charAt(1) + ' ' + remain + ' ' + b.substr(2);
      } else if (num < 30 && num > 7) {
        remain = Math.round(num / 7);
        let a = this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.weeksLeft'
        );
        let b = this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.weekLeft'
        );
        if (remain > 1) {
          return a.charAt(0) + a.charAt(1) + ' ' + remain + ' ' + a.substr(2);
        }
        return b.charAt(0) + b.charAt(1) + ' ' + remain + ' ' + b.substr(2);
      } else {
        remain = Math.round(num);
        let a = this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.daysLeft'
        );
        let b = this.coreService.getTranslation(
          'MANAGER_ENTRYPOINT.TIME.dayLeft'
        );
        if (remain > 1) {
          return (
            a.charAt(0) +
            a.charAt(1) +
            a.charAt(2) +
            ' ' +
            remain +
            ' ' +
            a.substr(3)
          );
        }
        return (
          b.charAt(0) +
          b.charAt(1) +
          a.charAt(2) +
          ' ' +
          remain +
          ' ' +
          b.substr(3)
        );
      }
    }
  }
  latestDate(data) {
    if (
      this.userInfo.language == 'pt' ||
      this.userInfo.language == 'fr' ||
      this.userInfo.language == 'de'
    ) {
      return data.charAt(0).toUpperCase() + data.substr(1).toLowerCase();
    } else {
      return data;
    }
  }
  timeLeftDays(num: number) {
    let remain;
    if (num < 0) {
      return this.coreService.getTranslation(
        'MANAGER_ENTRYPOINT.TIME.noDayLeft'
      );
    } else {
      remain = Math.round(num);
      if (remain > 1) {
        return (
          remain +
          ' ' +
          this.coreService.getTranslation('MANAGER_ENTRYPOINT.TIME.daysLeft')
        );
      }
      return (
        remain +
        ' ' +
        this.coreService.getTranslation('MANAGER_ENTRYPOINT.TIME.dayLeft')
      );
    }
  }
  mcRedirect(item) {
    if (item) {
      this.router.navigate(['/Manager/cycles'], {
        queryParams: { cycleid: item.Id, cState: 'Progress', replaceUrl: true },
      });
      this.modalRef.hide();
    }
  }

  goToProfile(id: any) {
    this.router.navigate(['/User/story'], {
      queryParams: { idVisitor: id },
      replaceUrl: true,
    });
    this.modalRef.hide();
  }

  openObjectiveSheet(objective: any, referralAction: REFERRAL_ACTION = 1) {
    this.pp360SpinnerService.showSpinner(true);
    if (objective.Visibility !== 'Private') {
      if (objective.Id) {
        this.srv
          .getObjectiveById(objective.Id, this.manager)
          .subscribe((objectiveResult: any) => {
            // console.log('objectiveResult', objectiveResult);
            this.showObjectiveSheet(objectiveResult, true, referralAction);
            this.pp360SpinnerService.showSpinner(false);
          });
      } else if (objective.OKRId) {
        this.srv
          .getObjectiveById(objective.OKRId, this.manager)
          .subscribe((objectiveResult: any) => {
            // console.log('objectiveResult', objectiveResult);
            this.showObjectiveSheet(objectiveResult, true, referralAction);
            this.pp360SpinnerService.showSpinner(false);
          });
      }
      document.getElementById('popup-show').style.display = 'none';
    }
    // this.modalRef.hide();
  }
  showObjectiveSheet(
    obj: any,
    readOnly: boolean = false,
    referralAction: REFERRAL_ACTION = 1
  ) {
    const content: any = {
      objective: obj,
      state: 'DEFINITION_STATE',
      cycle: this.currentCycle,

      ReadOnly: readOnly,
      referral: REFERRAL.CYCLES,
      referralAction: referralAction,
    };
    if (this.iamAdmin) {
      if (this.currentUserInfo.isManager && this.manager === 'AsManager') {
        content.ViewMode = VIEW_MODE.MANAGER;
      } else {
        content.ViewMode = VIEW_MODE.ADMIN;
      }
    } else {
      content.ViewMode = VIEW_MODE.MANAGER;
    }

    // console.log('showObjectiveSheet', content);

    switch (this.currentStatus) {
      case 'Progress':
        content.state = 'EXECUTION_STATE';
        break;
      case 'Evaluation':
        content.state = 'EVALUATION_STATE';
        break;
    }

    const modal = this.modalService.show(ObjSheetPopupComponent, {
      class: 'objective-sheet-popup okr-obj-popup',
      ignoreBackdropClick: false,
      initialState: {
        isHeader: true,
        isManager: true,
      },
    });

    (<ObjSheetPopupComponent>modal.content).showModal(content);
    (<ObjSheetPopupComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.emitReloadEvent();
        }
      }
    );

    // ----- Handles in sheets objective remotions
    (<ObjSheetPopupComponent>modal.content).assignObjective.subscribe(
      (result: any) => {
        this.companyReportService
          .assignObjective(result.id, result.objective)
          .subscribe((r) => {
            this.emitReloadEvent();
          });
      }
    );
  }
  emitReloadEvent() {
    this.reload.emit({
      searchInput: this.searchInput.nativeElement.value,
      advFilters: this.currentFilter,
      detailsRow: this.currentExpandedRow,
    });
  }
  currentExpandedRow: number;
  expandRow(row: any) {
    // console.log('expandRow', row);
    const oldState = row.ShowDetails;
    this.filteredEmployees.forEach((element) => {
      element.ShowDetails = false;
    });
    row.ShowDetails = oldState ? false : true;
    this.currentExpandedRow = row.ShowDetails ? row.$id : undefined;
    // console.log(this.currentExpandedRow);
  }

  gotoTop() {
    window.scroll({
      top: this.scrollPosition - 100,
      left: 0,
      behavior: 'smooth',
    });
    document.getElementById('scrolling').scroll(0, 0);
  }

  openOneCycle(item, i) {
    if (item) {
      this.router.navigate(['/Manager/cycles'], {
        queryParams: {
          cycleid: item.CycleId,
          cState: 'Definition',
          cSearch: i.Owner,
        },
      });
      this.modalRef.hide();
    }
  }
  openOneCycleAwait(item) {
    if (item) {
      this.router.navigate(['/Manager/cycles'], {
        queryParams: {
          cycleid: item.CycleId,
          cState: 'Definition',
          type: 'Await',
        },
      });
      this.modalRef.hide();
    }
  }
  openUnweightedCycle(item) {
    if (item) {
      this.router.navigate(['/Manager/cycles'], {
        queryParams: {
          cycleid: item.CycleId,
          cState: 'Definition',
          type: 'Unweighted',
        },
      });
      this.modalRef.hide();
    }
  }
  openOWLCycle(item) {
    if (item) {
      this.companyReportService
        .getMyCycleDetails(item.CycleId)
        .subscribe((myCycleDetails: any) => {
          this.currentCycle = myCycleDetails;
          if (this.currentCycle.Status !== 'Definition') {
            this.router.navigate(['/Manager/cycles'], {
              queryParams: {
                cycleid: item.CycleId,
                type: 'withoutLink',
                cState: 'Progress',
              },
            });
          } else if (this.currentCycle.Status === 'Definition') {
            this.router.navigate(['/Manager/cycles'], {
              queryParams: {
                cycleid: item.CycleId,
                type: 'withoutLink',
                cState: 'Definition',
              },
            });
          }
          this.modalRef.hide();
        });
    }
  }
  openOWKCycle(item) {
    if (item) {
      this.router.navigate(['/Manager/cycles'], {
        queryParams: {
          cycleid: item.CycleId,
          type: 'OWK',
          cState: 'Definition',
        },
      });
      this.modalRef.hide();
    }
  }

  mapLanguageArrayToObject(languageArray: any) {
    let obj = {};
    languageArray.forEach((language: any) => {
      obj[language.LanguageCode] = language;
    });
    return obj;
  }

  assignObjectives(cycleid, uData, datas) {
    this.srv
      .getSingleCycle(cycleid, true, 'AsManager')
      .toPromise()
      .then((cycle: any) => {
        let selectedCycle = {};

        Object.keys(cycle).forEach((key) => {
          let value = cycle[key];
          if (key === 'Name' || key === 'Description') {
            selectedCycle[`${key}`] = this.mapLanguageArrayToObject(cycle[key]);
          } else if (key === 'GlobalCategories' || key === 'CustomCategories') {
            selectedCycle[`${key}`] = JSON.parse(JSON.stringify(cycle[key]));
            selectedCycle[`${key}`].forEach((item: any) => {
              Object.keys(item).forEach((subKey) => {
                if (subKey === 'Name' || subKey === 'Description') {
                  item[`${subKey}`] = this.mapLanguageArrayToObject(
                    item[subKey]
                  );
                }
              });
            });
          } else {
            selectedCycle[`${key}`] = cycle[key];
          }
        });
        this.cycle = selectedCycle;

        this.userInfo = this.coreService.getUserInfo();
        this.isCompanyAdmin = this.userInfo.roles.indexOf('CompanyAdmin') >= 0;

        if (
          this.cyclesUtilitiesService.showExecutionPhase(
            this.cycle,
            this.isCompanyAdmin ? 'ADMIN' : 'MANAGER'
          )
        ) {
          this.currentState = 'Progress';
        } else {
          this.currentState = 'Definition';
        }

        const obj: any = {
          Author: {
            Id: this.userInfo.linkedEmployeeId,
            Picture: this.userInfo.imageUrl,
            Name: this.userInfo.fullName,
          },
          Name: '',
          Description: '',
          Image: undefined,
          Category: undefined,
          DueDate: this.cycle.CycleInfo.Period.End,
          Visibility: this.cycle.WhoCanEdit,
          LimitedViewer: [],
          CheckList: [],
          Comments: [],
          Weight: 0,
          Employees: [],
          ApprovalStatus: 'None',
          SelfEvaluationValue: 0,
          ChallengeValue: -1,
          InclinationValue: -1,
          PostInclinationValue: -1,
          PostChallengeValue: -1,
          Evaluation: 0,
          DefinitionState: 2, //this.cyclesUtilitiesService.afterDefinitionPhase(this.cycle, this.isCompanyAdmin ? 'ADMIN' : 'MANAGER') ? 2 : 0
        };
        // console.log(uData);
        obj.Employees = [uData.Employee];
        // obj['Id']=uData.Employee.Name;
        obj['Id'] = 'direct';

        let state = 'DEFINITION_STATE';
        switch (this.currentState) {
          case 'Progress':
            state = 'EXECUTION_STATE';
            break;
          case 'Evaluation':
            state = 'EVALUATION_STATE';
            break;
        }

        const content: any = {
          objective: obj,
          state: state,
          cycle: this.cycle,
          ViewMode: this.isCompanyAdmin ? VIEW_MODE.ADMIN : VIEW_MODE.MANAGER,
          referral: REFERRAL.CYCLES,
          referralAction: REFERRAL_ACTION.ASSIGN,
        };

        // const modal = this.modalService.show(ObjSheetPopupComponent, { class: 'objective-sheet-popup' });
        const modal = this.modalService.show(ObjSheetPopupComponent, {
          class: 'objective-sheet-popup okr-obj-popup-manager',
          ignoreBackdropClick: false,
          backdrop: 'static',
          initialState: {
            isHeader: true,
            isManager: true,
            olddata: datas,
          },
        });
        (<ObjSheetPopupComponent>modal.content).showModal(content);
        (<ObjSheetPopupComponent>modal.content).onClose.subscribe(
          (result: boolean) => {
            if (result) {
              this.emitReloadEvent();
            }
          }
        );

        // ----- Handles in sheets objective remotions
        (<ObjSheetPopupComponent>modal.content).assignObjective.subscribe(
          (result: any) => {
            this.companyReportService
              .assignObjective(result.id, result.objective)
              .subscribe((r) => {
                this.emitReloadEvent();
              });
          }
        );
      });
    let i = document.getElementById('popup-show').style.display;
    this.modalRef.hide();
    // document.getElementById("popup-show").style.display = "none";
  }

  cancelBtn(item) {
    // console.log("cancel");
    item.krData = this.krData;
    item.measure = this.filterTypeArr[0].value;
    item.start = this.start;
    item.target = this.target;
    item.measureName = this.measureName;
    this.reloadAct = false;
    setTimeout(() => {
      this.reloadAct = true;
      this.openId = '';
      this.extra2Id = '';
    });
  }

  openCycletoEval(item) {
    if (item) {
      this.router.navigate(['/Manager/cycles'], {
        queryParams: { cycleid: item.CycleId, cState: 'Evaluation' },
      });
      this.modalRef.hide();
    }
  }

  onWeightedChange() {
    this.isWeighted != this.isWeighted;
  }
}
