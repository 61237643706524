import { Injectable } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { CoreService } from './core.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment/min/locales';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ScreenSizeService } from 'app/shared/services/screen-size.service';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class navigationService {
  public defaultNavigationItem: any;
  public managerReportItem: any;
  public companyReportItem: any;
  public globalReportitem: any;
  public localReportItem: any;
  public menuOpenMap = {};
  public onlyReceivedHide: boolean = false;
  public onlyGivenHide: boolean = false;
  public isMobile: boolean = false;
  public hideToolbar = new BehaviorSubject<boolean>(false);
  public updateSideMenu = new BehaviorSubject<boolean>(false);
  public hideToolbarObsv$ = this.hideToolbar.asObservable();
  oldMenu: any;

  constructor(
    public coreService: CoreService,
    public screenSizeService: ScreenSizeService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.readUserInfo();
    this.coreService.userInfoChanged.subscribe((arg) =>
      this.manageUserInfoChanged(arg)
    );
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 991;
    });
  }
  public GetMenuStatus(key): boolean {
    return this.menuOpenMap[key] || false;
  }

  CloseAllMenu() {
    // console.log(this.menuOpenMap)
    // Imposto tutti a false, siccome ne apro solo uno alla volta
    Object.keys(this.menuOpenMap).map(
      (menu) => (this.menuOpenMap[menu] = false)
    );
  }

  public ToggleMenuStatus(key: string): any {
    const previousMenu = this.oldMenu;
    const currentMenu = key;
    if (previousMenu !== currentMenu) {
      this.CloseAllMenu();
    }
    this.oldMenu = currentMenu;
    const oldStatus = this.GetMenuStatus(key);
    this.menuOpenMap[key] = !oldStatus;
    return this.menuOpenMap[key];
  }

  manageUserInfoChanged(arg) {
    this.readUserInfo();
  }

  async readUserInfo() {
    let localUser = this.coreService.getUserInfo();
    var lang = 'en';
    if (localUser) {
      lang = localUser['language'];
      if (!lang) lang = 'en';
    } else {
      lang = this.translate.getBrowserLang();
    }

    let x = lang.match(/en|it|pl|nl|es|pt|de|ru|zh|fr/) ? lang : 'en';
    moment.locale(x);
    this.setupMenus();
    if (this.translate.currentLang != x) {
      await this.translate.use(x).toPromise<void>();
    }

    if (x === 'zh') {
      moment.locale(x + '-cn');
    }

    this.setupMenus();
  }

  setUpdateSideMenu(value) {
    this.updateSideMenu.next(value);
  }

  getUpdateSideMenu() {
    return this.updateSideMenu.asObservable();
  }

  /**
   * USER MENU
   */
  setupMenus() {
    this.defaultNavigationItem = {
      theWall: {
        title: 'Menu.TheWall',
        refName: 'feed',
        iClass: 'fa fa-book sideIcon',
        routerLink: `/User/wall/feed`,
        subChild: [],
      },
      myStory: {
        title: 'Menu.MyStory',
        refName: 'story',
        iClass: 'fas fa-address-card sideIcon',
        routerLink: `/User/story`,
        subChild: [],
      },
      myOrgChart: {
        title: 'Menu.OrganisationalChart',
        refName: 'orgchart',
        iClass: 'fa fa-sitemap sideIcon',
        routerLink: `/User/orgchart`,
        subChild: [],
      },
      MyOpinion: {
        title: 'Menu.MyOpinion',
        refName: 'opionion',
        iClass: 'fas fa-microphone sideIcon',
        routerLink: `/User/opinion`,
        subChild: [],
      },
      OKRsNetwork: {
        title: 'Menu.OKRNetworks',
        refName: 'okrsNetworks',
        iClass: 'fa fa-link sideIcon',
        routerLink: `/OKRs/report/OKRNetworks`,
        subChild: [],
      },
      OKRs: {
        title: 'Menu.MyOKRs',
        refName: 'OKRs',
        iClass: 'fa fa-bullseye sideIcon',
        routerLink: `/OKRs/report/myOKRs`,
        subChild: [],
      },

      myObjectives: {
        title: 'Menu.MyObjectives',
        refName: 'report/my-objectives',
        routerLink: '/Objectives/report/my-objectives',
        iClass: 'fa fa-bullseye sideIcon',
        subChild: [],
      },

      growthItem: {
        title: 'Menu.MyGrowth',
        refName: 'MyGrowth',
        refChildName: 'Personal-Growth',
        iClass: 'fa fa-rocket sideIcon',
        subChild: [],
      },

      personalReport: {
        title: 'Menu.MyAnalitics',
        refName: 'MyAnaliticspersonal-report',
        refChildName: 'Personal',
        iClass: 'fa fa-bar-chart sideIcon',
        subChild: [
          {
            title: 'Menu.Performance',
            refName: 'Personal/report/performance',
            routerLink: '/Personal/report/performance',
            iClass: 'fa fa-rocket sideIcon',
            isChild: true,
          },
          {
            title: 'Menu.CompareToRole',
            refName: 'Personal/report/compare-role',
            routerLink: '/Personal/report/compare-role',
            iClass: 'fa fa-rocket sideIcon',
            isChild: true,
          },
        ],
      },

      myWallet: {
        title: 'Menu.MyWallet',
        refName: 'mywallet',
        refChildName: 'MyWallet',
        iClass: 'fa fa-credit-card-alt sideIcon',
        subChild: [
          {
            title: 'Menu.ExchangeTokens',
            refName: 'exchange-tokens',
            routerLink: '/MyWallet/exchange-tokens',
            isChild: true,
          },
          {
            title: 'Menu.MyCoupons',
            refName: 'my-coupons',
            routerLink: '/MyWallet/my-coupons',
            isChild: true,
          },
          {
            title: 'Menu.PartecipantLTI',
            refName: 'partecipantLTI',
            routerLink: '/PartecipantLTI/recipient',
            isChild: true,
          },
          {
            title: 'Menu.PatPoints',
            refName: 'patpoints',
            routerLink: '/MyWallet/patpoints',
            isChild: true,
          },
        ],
      },
    };

    //-----------------------   ADDS Submenus of My Growth
    this.defaultNavigationItem.growthItem.subChild.push({
      title: 'Menu.Praise',
      refName: 'employee-personal-growth/praise',
      routerLink: '/Personal-Growth/employee-personal-growth/praise',
      iClass: 'fa fa-empire sideIcon',
      isChild: true,
    });
    this.defaultNavigationItem.growthItem.subChild.push({
      title: 'Menu.FindMyGuru',
      refName: 'employee-personal-growth/find-my-guru',
      routerLink: '/Personal-Growth/employee-personal-growth/find-my-guru',
      iClass: 'fa fa-rocket sideIcon',
      isChild: true,
    });
    this.defaultNavigationItem.growthItem.subChild.push({
      title: 'Menu.PerformanceReview',
      refName: 'employee-personal-growth/performance-review',
      iClass: 'fa fa-empire sideIcon',
      routerLink:
        '/Personal-Growth/employee-personal-growth/performance-review',
      isChild: true,
    });

    if (environment.ShowGrowthSummary) {
      this.defaultNavigationItem.growthItem.subChild.push({
        title: 'Menu.Summary',
        refName: 'Personal-Growth/summary',
        routerLink: '/Personal-Growth/summary',
        iClass: 'fa fa-rocket sideIcon',
        isChild: true,
        hideOnMobile: true,
      });
    }

    if (environment.ShowBossAndSelfEvaluations) {
      this.defaultNavigationItem.growthItem.subChild.push({
        title: 'Menu.SelfEvaluation',
        refName: 'employee-personal-growth/self-evaluation',
        routerLink: '/Personal-Growth/employee-personal-growth/self-evaluation',
        iClass: 'fa fa-rocket sideIcon',
        isChild: true,
        hideOnMobile: true,
      });

      this.defaultNavigationItem.growthItem.subChild.push({
        title: 'Menu.BossEvaluation',
        refName: 'employee-personal-growth/boss-evaluation',
        routerLink: '/Personal-Growth/employee-personal-growth/boss-evaluation',
        iClass: 'fa fa-rocket sideIcon',
        isChild: true,
        hideOnMobile: true,
      });

      this.defaultNavigationItem.growthItem.subChild.push({
        title: 'Menu.PeerToPeer',
        refName: 'employee-personal-growth/peer-to-peer',
        routerLink: '/Personal-Growth/employee-personal-growth/peer-to-peer',
        iClass: 'fa fa-rocket sideIcon',
        isChild: true,
        hideOnMobile: true,
      });
    }

    // {
    //     title:'Menu.MyGoals',
    //     refName:'employee-personal-growth/my-goals',
    //     routerLink:'/Personal-Growth/employee-personal-growth/my-goals',
    //     iClass: 'fa fa-rocket sideIcon',
    // },

    /*
    {
        title:'employee-personal-growth',
        refName:'employee-personal-growth/employee-personal-growth',
        routerLink: '/Personal-Growth/employee-personal-growth',
        iClass: 'fa fa-rocket sideIcon',
    },
    */

    this.managerReportItem = {
      dashboard: {
        title: 'Menu.MyDashboard',
        iClass: 'fa fa-th-large sideIcon',
        refName: 'Manager/dashboard',
        routerLink: '/Manager/dashboard',
        subChild: [],
      },
      // TileMenu/menu/team-administration
      companyobjectiveCycles: {
        title: 'Company.EnableObjectiveCycles',
        refName: 'objectiveCycle',
        iClass: 'fa fa-bullseye sideIcon',
        routerLink: `/Manager/cycles`,
        subChild: [],
      },
      teamGrowth: {
        title: 'Menu.teamGrowth',
        refName: 'teamGrowth',
        refChildName: 'teamGrowth',
        iClass: 'fa fa-rocket sideIcon',
        subChild: [
          {
            title: 'Menu.Analytics',
            refName: 'Analytics/manager',
            routerLink: '/Analytics/manager',
            isChild: true,
          },
          {
            title: 'Menu.Evaluations',
            refName: 'Manager/evaluations',
            routerLink: '/Manager/evaluations',
            isChild: true,
          },
        ],
      },
      analytics: {
        title: 'Menu.Analytics',
        refName: 'Analytics/manager',
        iClass: 'fa fa-line-chart sideIcon',
        routerLink: '/Analytics/manager',
        subChild: [],
      },
      /*
      managerReport: {
        title: 'Menu.SkillAnalytics',
        refChildName: 'Manager',
        iClass: 'fa fa-briefcase sideIcon',
        routerLink: '/TileMenu/menu/team-analytics',
        subChild: []
      },
      */

      administration: {
        title: 'Menu.Administration',
        refName: 'TileMenu/menu/team-administration',
        iClass: 'fa fa-cog sideIcon',
        routerLink: '/TileMenu/menu/team-administration',
        subChild: [],
      },

      goals: {
        title: 'Menu.Goals',
        refName: 'goals',
        iClass: 'fa fa-bullseye sideIcon',
        routerLink: `/Manager/goals`,
        subChild: [],
      },
    };

    this.localReportItem = {
      administration: {
        title: 'Menu.Administration',
        refName: 'menu/local-admin',
        iClass: 'fa fa-cog sideIcon',
        routerLink: '/TileMenu/menu/local-admin',
        subChild: [],
      },
      analytics: {
        title: 'Menu.Analytics',
        refName: 'Analytics/admin',
        iClass: 'fa fa-pie-chart sideIcon ',
        routerLink: '/Analytics/localadmin',
        subChild: [],
      },
      myOrgChart: {
        title: 'Menu.OrganisationalChart',
        refName: 'orgchart',
        iClass: 'fa fa-sitemap sideIcon',
        routerLink: `/User/orgchart`,
        subChild: [],
      },

      companypulseSurvey: {
        title: 'Employee.PulseSurvey',
        refName: 'Surveys',
        iClass: 'fas fa-microphone sideIcon',
        routerLink: `/Surveys/CompanyAdmin`,
        subChild: [],
      },

      companyobjectiveCycles: {
        title: 'Company.EnableObjectiveCycles',
        refName: 'objectiveCycle',
        iClass: 'fa fa-bullseye sideIcon',
        routerLink: `/CompanyAdmin/companies/cycles/local`,
        subChild: [],
      },

      companyWallet: {
        title: 'Menu.DivisionWallet',
        refName: 'mywallet2',
        refChildName: 'MyWallet2',
        iClass: 'fa fa-credit-card-alt sideIcon',
        subChild: [
          {
            title: 'Menu.TokenSettings',
            refName: 'TokenSettings',
            routerLink: `/CompanyAdmin/token-settings/local`,
            isChild: true,
          },
          {
            title: 'Menu.AssignToken',
            refName: 'AssignToken',
            routerLink: `/CompanyAdmin/assign-token`,
            isChild: true,
          },
          {
            title: 'Menu.patPrize',
            refName: 'patprizes',
            routerLink: `/CompanyAdmin/patprizes`,
            isChild: true,
          },
          {
            title: 'Menu.Plans',
            refName: 'plans',
            routerLink: '/AdminLTI/plans',
            isChild: true,
          },
          {
            title: 'Menu.Results',
            refName: 'results',
            routerLink: '/AdminLTI/results',
            isChild: true,
          },
        ],
      },

      InapproriateReports: {
        title: 'Menu.InapproriateReports',
        refName: 'acompanies_inapproriate',
        iClass: 'fas fa-flag sideIcon',
        routerLink: `/CompanyAdmin/inappropriate/local`,
        subChild: [],
      },
    };

    this.companyReportItem = {
      analytics: {
        title: 'Menu.Analytics',
        refName: 'Analytics/admin',
        iClass: 'fa fa-line-chart sideIcon',
        routerLink: '/Analytics/admin',
      },

      myOrgChart: {
        title: 'Menu.OrganisationalChart',
        refName: 'orgchart',
        iClass: 'fa fa-sitemap sideIcon',
        routerLink: `/User/orgchart`,
        subChild: [],
      },

      companypulseSurvey: {
        title: 'Employee.PulseSurvey',
        refName: 'Surveys',
        iClass: 'fas fa-microphone sideIcon',
        routerLink: `/Surveys/CompanyAdmin`,
        subChild: [],
      },

      companyobjectiveCycles: {
        title: 'Company.EnableObjectiveCycles',
        refName: 'objectiveCycle',
        iClass: 'fa fa-bullseye sideIcon',
        routerLink: `/CompanyAdmin/companies/cycles/global`,
        subChild: [],
      },

      companyGrowth: {
        title: 'Menu.CompanyGrowth',
        refName: 'companyGrowth',
        refChildName: 'CompanyGrowth',
        iClass: 'fa fa-rocket sideIcon',
        subChild: [
          {
            title: 'Menu.Analytics',
            refName: 'Analytics/admin',
            routerLink: 'Analytics/admin',
            isChild: true,
          },
          {
            title: 'Menu.PerformanceReviews',
            refName: 'PerformanceReviews',
            routerLink: '/CompanyAdmin/performace-reviews',
            isChild: true,
          },
        ],
      },

      companyWallet: {
        title: 'Menu.CompanyWallet',
        refName: 'mywallet1',
        refChildName: 'MyWallet1',
        iClass: 'fa fa-credit-card-alt sideIcon',
        subChild: [
          {
            title: 'Menu.TokenSettings',
            refName: 'TokenSettings',
            routerLink: `/CompanyAdmin/token-settings/global`,
            isChild: true,
          },
          {
            title: 'Menu.AssignToken',
            refName: 'AssignToken',
            routerLink: `/CompanyAdmin/assign-token`,
            isChild: true,
          },
          {
            title: 'Menu.patPrize',
            refName: 'patprizes',
            routerLink: `/CompanyAdmin/patprizes`,
            isChild: true,
          },
          {
            title: 'Menu.Plans',
            refName: 'plans',
            routerLink: '/AdminLTI/plans',
            isChild: true,
          },
          {
            title: 'Menu.Results',
            refName: 'results',
            routerLink: '/AdminLTI/results',
            isChild: true,
          },
        ],
      },

      InapproriateReports: {
        title: 'Menu.InapproriateReports',
        refName: 'acompanies_inapproriate',
        iClass: 'fas fa-flag sideIcon',
        routerLink: `/CompanyAdmin/inappropriate/global`,
        subChild: [],
      },

      /*
      companyReport: {
        title: 'Menu.SkillAnalytics',
        refName: 'CompanyAnalytics',
        iClass: 'fa fa-briefcase sideIcon',
        routerLink: '/TileMenu/menu/company-analytics',
        subChild: []
      },
      */

      administration: {
        title: 'Menu.Administration',
        refName: 'menu/company-admin',
        iClass: 'fa fa-cog sideIcon',
        routerLink: '/TileMenu/menu/company-admin',
        subChild: [],
      },

      integrations: {
        title: 'Menu.Integrations',
        refName: 'menu/my-integrations',
        iClass: 'fas fa-plug sideIcon',
        routerLink: '/TileMenu/menu/my-integrations',
        subChild: [],
      },

      performanceReviews: {
        title: 'Menu.PerformanceReviews',
        refName: 'PerformanceReviews',
        iClass: 'fa fa-cog sideIcon',
        routerLink: '/CompanyAdmin/performace-reviews',
        subChild: [],
      },
      // themePalette: {
      //   title: "Menu.ThemePalette",
      //   refName: "ThemePalette",
      //   iClass: "fas fa-palette sideIcon",
      //   routerLink: "/CompanyAdmin/theme-palette",
      //   subChild: [],
      // },
    };

    if (!environment.ShowPerfomanceReviews) {
      delete this.companyReportItem.performanceReviews;
    }

    this.globalReportitem = {
      globalReport: {
        title: 'Menu.GlobalAnalitics',
        refName: 'globalReports/employeeReport',
        iClass: 'fa fa-sitemap sideIcon',
        routerLink: '/Global/globalReports/employeeReport',
        subChild: [],
      },
    };
  }

  /**
   * Employee Menus
   * @param userInfo
   */
  getNavigationItems(userInfo) {
    if (this.isMobile) {
      this.defaultNavigationItem = {
        theWall: {
          title: 'Menu.TheWall',
          refName: 'feed',
          iClass: 'fa fa-book sideIcon',
          routerLink: `/User/wall/feed`,
          subChild: [],
        },
        myStory: {
          title: 'Menu.MyStory',
          refName: 'story',
          iClass: 'fas fa-address-card sideIcon',
          routerLink: `/User/story`,
          subChild: [],
        },
        // myOrgChart: { // hide for mobile side
        //   title: 'Menu.OrganisationalChart',
        //   refName: 'orgchart',
        //   iClass: 'fa fa-sitemap sideIcon',
        //   routerLink: `/User/orgchart`,
        //   subChild: [],
        // },
        MyOpinion: {
          title: 'Menu.MyOpinion',
          refName: 'opionion',
          iClass: 'fas fa-microphone sideIcon',
          routerLink: `/User/opinion`,
          subChild: [],
        },
        OKRs: {
          title: 'Menu.MyOKRs',
          refName: 'OKRs/report/myOKRs',
          routerLink: '/OKRs/report/myOKRs',
          iClass: 'fa fa-bullseye sideIcon',
          subChild: [],
        },

        myObjectives: {
          title: 'Menu.MyObjectives',
          refName: 'report/my-objectives',
          routerLink: '/Objectives/report/my-objectives',
          iClass: 'fa fa-bullseye sideIcon',
          subChild: [],
        },
        growthItem: {
          title: 'Menu.MyGrowth',
          refName: 'MyGrowth',
          refChildName: 'Personal-Growth',
          iClass: 'fa fa-rocket sideIcon',
          subChild: [],
        },
        myWallet: {
          title: 'Menu.MyWallet',
          refName: 'mywallet',
          refChildName: 'MyWallet',
          iClass: 'fa fa-credit-card-alt sideIcon',
          subChild: [
            {
              title: 'Menu.ExchangeTokens',
              refName: 'exchange-tokens',
              routerLink: '/MyWallet/exchange-tokens',
              isChild: true,
            },
            {
              title: 'Menu.MyCoupons',
              refName: 'my-coupons',
              routerLink: '/MyWallet/my-coupons',
              isChild: true,
            },
            {
              title: 'Menu.PartecipantLTI',
              refName: 'partecipantLTI',
              routerLink: '/PartecipantLTI/recipient',
              isChild: true,
            },
            {
              title: 'Menu.PatPoints',
              refName: 'patpoints',
              routerLink: '/MyWallet/patpoints',
              isChild: true,
            },
          ],
        },
      };
      //-----------------------   ADDS Submenus of My Growth
      this.defaultNavigationItem.growthItem.subChild.push({
        title: 'Menu.Praise',
        refName: 'employee-personal-growth/praise',
        routerLink: '/Personal-Growth/employee-personal-growth/praise',
        iClass: 'fa fa-empire sideIcon',
        isChild: true,
      });
      this.defaultNavigationItem.growthItem.subChild.push({
        title: 'Menu.FindMyGuru',
        refName: 'employee-personal-growth/find-my-guru',
        routerLink: '/Personal-Growth/employee-personal-growth/find-my-guru',
        iClass: 'fa fa-rocket sideIcon',
        isChild: true,
      });
      // this.defaultNavigationItem.growthItem.subChild.push({
      //   title : "Performance Review",
      //   refName : "employee-personal-growth/performance-review",
      //   iClass : "fa fa-empire sideIcon",
      //   routerLink : "/Personal-Growth/employee-personal-growth/performance-review",
      //   isChild : true,
      // });
    }
    var items = [];
    items.push(this.defaultNavigationItem.theWall);
    if (!this.isMobile && userInfo.hasObjectiveCycles) {
      items.push(this.defaultNavigationItem.OKRsNetwork);
    }
    if (!this.isMobile) {
      items.push(this.defaultNavigationItem.myOrgChart);
    }

    items.push(this.defaultNavigationItem.myStory);

    if (userInfo.hasSurveys) items.push(this.defaultNavigationItem.MyOpinion);

    if (userInfo.hasObjectiveCycles) {
      items.push(this.defaultNavigationItem.OKRs);
    }

    var growthItemClone = JSON.parse(
      JSON.stringify(this.defaultNavigationItem.growthItem)
    );
    if (!userInfo.hasBossEvaluation) {
      this.removeMenu(
        'employee-personal-growth/boss-evaluation',
        growthItemClone.subChild
      );
    }
    if (!userInfo.hasSelfEvaluations) {
      this.removeMenu(
        'employee-personal-growth/self-evaluation',
        growthItemClone.subChild
      );
    }
    if (!userInfo.hasEvaluationFeed) {
      this.removeMenu(
        'employee-personal-growth/peer-to-peer',
        growthItemClone.subChild
      );
    }
    if (!userInfo.hasSkills) {
      this.removeMenu(
        'employee-personal-growth/find-my-guru',
        growthItemClone.subChild
      );
    }
    if (!userInfo.hasPerformanceReview) {
      this.removeMenu(
        'employee-personal-growth/performance-review',
        growthItemClone.subChild
      );
    }
    if (
      (!userInfo.companyHasFeedback &&
        !userInfo.companyHasPat &&
        !userInfo.companyHasToken) ||
      (!userInfo.receiveEvaluationFeed &&
        !userInfo.receivePatFeed &&
        !userInfo.receiveTokenFeed &&
        !userInfo.hasEvaluationFeed &&
        !userInfo.hasPatFeed &&
        !userInfo.hasTokenFeed)
    ) {
      this.removeMenu(
        'employee-personal-growth/praise',
        growthItemClone.subChild
      );
    }
    if (
      !userInfo.companyHasFeedback &&
      !userInfo.companyHasPat &&
      !userInfo.companyHasToken
    ) {
      this.removeMenu(
        'employee-personal-growth/find-my-guru',
        growthItemClone.subChild
      );
    }
    items.push(growthItemClone);

    if (environment.ShowPersonalReport && !this.isMobile) {
      items.push(this.defaultNavigationItem.personalReport);
    }

    let walletClone: any = JSON.parse(
      JSON.stringify(this.defaultNavigationItem.myWallet)
    );
    if (!userInfo.hasPatPrize) {
      this.removeMenu('patpoints', walletClone.subChild);
    }
    if (
      !userInfo.availableLTI ||
      userInfo.availableLTI.length == 0 ||
      !userInfo.hasLTI
    ) {
      this.removeMenu('partecipantLTI', walletClone.subChild);
    }
    // if (!userInfo.hasTokenExchangers || !userInfo.hasTokenFeed) {
    if (!userInfo.companyHasToken) {
      this.removeMenu('my-coupons', walletClone.subChild);
      this.removeMenu('exchange-tokens', walletClone.subChild);
    }
    if (
      userInfo.availableLTI &&
      userInfo.availableLTI.length > 0 &&
      userInfo.hasLTI
    ) {
      this.removeMenu('partecipantLTI', walletClone.subChild);
      try {
        userInfo.availableLTI.forEach((e: any) => {
          var mnu = {
            title: 'LTI: ' + e.Name,
            refName: 'partecipantLTI/plan/' + e.Id,
            routerLink: `/PartecipantLTI/recipient/${e.Id}`,
            isChild: true,
            hideOnMobile: true,
          };
          walletClone.subChild.push(mnu);
        });
      } catch {}
    }
    if (
      (userInfo.hasTokenExchangers && userInfo.hasTokenFeed) ||
      (userInfo.availableLTI && userInfo.availableLTI.length > 0) ||
      userInfo.hasPatPrize
    ) {
      items.push(walletClone);
    }

    return items;
  }
  /**
   * Manager Team Menu
   * @param userInfo
   */
  getManagerNavigationItems(userInfo) {
    var items = [];
    // if (userInfo.isManager) {
    //items.push(this.managerReportItem.managerReport);
    // }
    // if (userInfo.isEvaluator) {
    // items.push(this.managerReportItem.dashboard);
    // if (
    //   userInfo?.companyHasFeedback ||
    //   userInfo?.companyHasPat ||
    //   userInfo?.companyHasToken
    // ) {
    //   items.push(this.managerReportItem.teamGrowth);
    // }companyobjectiveCycles

    //--TODO --- Remove comemnt
    //items.push(this.managerReportItem.goals);
    // }
    items.push(this.managerReportItem.dashboard);
    if (userInfo.hasObjectiveCycles) {
      items.push(this.managerReportItem.companyobjectiveCycles);
    }
    let teamGrowthClone: any = JSON.parse(
      JSON.stringify(this.managerReportItem.teamGrowth)
    );
    if (
      (!userInfo?.companyHasFeedback &&
        !userInfo?.companyHasPat &&
        !userInfo?.companyHasToken) ||
      (this.onlyGivenHide && this.onlyReceivedHide)
    ) {
      this.removeMenu('Analytics/manager', teamGrowthClone.subChild);
    }
    items.push(teamGrowthClone);
    return items;
  }

  /**
   * Company Admin Menus
   * @param userInfo
   */
  getCompanyAdminNavigationItems(userInfo) {
    var items = [];
    // console.log(userInfo);

    //items.push(this.companyReportItem.companyReport);
    // items.push(this.companyReportItem.analytics);
    items.push(this.companyReportItem.administration);
    items.push(this.companyReportItem.myOrgChart);
    if (userInfo.hasSurveys) {
      items.push(this.companyReportItem.companypulseSurvey);
    }
    if (userInfo.hasObjectiveCycles) {
      this.companyReportItem.companyobjectiveCycles.routerLink = `/CompanyAdmin/companies/cycles/${userInfo.belongingCompanyId}/global`;
      items.push(this.companyReportItem.companyobjectiveCycles);
    }
    let companyClone: any = JSON.parse(
      JSON.stringify(this.companyReportItem.companyGrowth)
    );
    if (
      (!userInfo?.companyHasFeedback &&
        !userInfo?.companyHasPat &&
        !userInfo?.companyHasToken) ||
      (this.onlyGivenHide && this.onlyReceivedHide)
    ) {
      this.removeMenu('Analytics/admin', companyClone.subChild);
    }
    if (!userInfo.hasPerformanceReview) {
      this.removeMenu('PerformanceReviews', companyClone.subChild);
    }
    if (
      userInfo?.companyHasFeedback ||
      userInfo?.companyHasPat ||
      userInfo?.companyHasToken ||
      userInfo?.hasPerformanceReview
    ) {
      items.push(companyClone);
    }
    this.companyReportItem.companyWallet.subChild[2].routerLink = `/CompanyAdmin/patprizes/${userInfo.belongingCompanyId}`;
    let walletCompany: any = JSON.parse(
      JSON.stringify(this.companyReportItem.companyWallet)
    );
    if (!userInfo.hasPatPrize) {
      this.removeMenu('patprizes', walletCompany.subChild);
    }
    if (!userInfo.hasLTI) {
      this.removeMenu('plans', walletCompany.subChild);
      this.removeMenu('results', walletCompany.subChild);
    }
    if (!userInfo.companyHasToken) {
      this.removeMenu('TokenSettings', walletCompany.subChild);
      this.removeMenu('AssignToken', walletCompany.subChild);
    }
    items.push(walletCompany);
    // items.push(this.companyReportItem.companyWallet);
    items.push(this.companyReportItem.InapproriateReports);
    // if (
    //   userInfo?.companyHasFeedback ||
    //   userInfo?.companyHasPat ||
    //   userInfo?.companyHasToken
    // ) {
    //   items.push(this.companyReportItem.analytics);
    // }
    // items.push(this.companyReportItem.integrations);
    // if (environment.ShowPerfomanceReviews)
    //   items.push(this.companyReportItem.performanceReviews);
    // items.push(this.companyReportItem.themePalette);

    return items;
  }

  getLocalAdminNavigationItems(userInfo) {
    var items = [];

    // items.push(this.localReportItem.analytics);
    // if (
    //   userInfo?.companyHasFeedback ||
    //   userInfo?.companyHasPat ||
    //   userInfo?.companyHasToken
    // ) {
    //   items.push(this.companyReportItem.analytics);
    // }
    if (
      userInfo.belongingCompanyId !== '6ce7fc9b-c438-4d15-aabb-1b3565435bd6'
    ) {
      items.push(this.localReportItem.administration);
      items.push(this.localReportItem.myOrgChart);
      if (userInfo.hasSurveys) {
        items.push(this.localReportItem.companypulseSurvey);
      }
      if (userInfo.hasObjectiveCycles) {
        this.localReportItem.companyobjectiveCycles.routerLink = `/CompanyAdmin/companies/cycles/${userInfo.belongingCompanyId}/local`;
        items.push(this.localReportItem.companyobjectiveCycles);
      }
      if (
        userInfo?.companyHasFeedback ||
        userInfo?.companyHasPat ||
        userInfo?.companyHasToken
      ) {
        if (!this.onlyGivenHide || !this.onlyReceivedHide) {
          items.push(this.localReportItem.analytics);
        }
      }
      this.localReportItem.companyWallet.subChild[2].routerLink = `/CompanyAdmin/patprizes/${userInfo.belongingCompanyId}`;
      let walletLocal: any = JSON.parse(
        JSON.stringify(this.companyReportItem.companyWallet)
      );
      if (!userInfo.hasPatPrize) {
        this.removeMenu('patprizes', walletLocal.subChild);
      }
      if (!userInfo.hasLTI) {
        this.removeMenu('plans', walletLocal.subChild);
        this.removeMenu('results', walletLocal.subChild);
      }
      if (!userInfo.companyHasToken) {
        this.removeMenu('TokenSettings', walletLocal.subChild);
        this.removeMenu('AssignToken', walletLocal.subChild);
      }
      items.push(walletLocal);
      // items.push(this.localReportItem.companyWallet);
      items.push(this.localReportItem.InapproriateReports);
      // items.push(this.companyReportItem.integrations);

      return items;
    } else if (
      userInfo.belongingCompanyId === '6ce7fc9b-c438-4d15-aabb-1b3565435bd6'
    ) {
      // items.push(this.localReportItem.administration);
      // items.push(this.localReportItem.myOrgChart);
      // items.push(this.localReportItem.companypulseSurvey);
      if (userInfo.hasObjectiveCycles) {
        this.localReportItem.companyobjectiveCycles.routerLink = `/CompanyAdmin/companies/cycles/${userInfo.belongingCompanyId}/local`;
        items.push(this.localReportItem.companyobjectiveCycles);
      }
      if (
        userInfo?.companyHasFeedback ||
        userInfo?.companyHasPat ||
        userInfo?.companyHasToken
      ) {
        if (!this.onlyGivenHide || !this.onlyReceivedHide) {
          items.push(this.localReportItem.analytics);
        }
      } // this.localReportItem.companyWallet.subChild[2].routerLink=`/CompanyAdmin/patprizes/${userInfo.belongingCompanyId}`;
      // items.push(this.localReportItem.companyWallet);
      // items.push(this.localReportItem.InapproriateReports);
      // items.push(this.companyReportItem.integrations);
      // console.log(items);
      // console.log(userInfo);
      return items;
    }
  }
  /**
   * Super User menu
   * @param userInfo
   */
  getSuperAdminNavigationItems(userInfo) {
    var items = [];

    items.push({
      title: 'Menu.Administration',
      refName: 'menu/global',
      iClass: 'fa fa-cogs sideIcon',
      routerLink: '/TileMenu/menu/global',
      subChild: [],
    });

    return items;
  }

  /**
   * Submenus Team Analyitcs
   */
  getTeamAnalyticsMenuItems(userInfo) {
    let menus = [
      {
        title: 'Menu.Performance',
        refName: 'manager-report/performance',
        routerLink: '/Manager/manager-report/performance',
        iClass: 'fa fa-trophy sideIcon',
      },
      {
        title: 'Menu.PerformanceCompare',
        refName: 'manager-report/performance-compare',
        routerLink: '/Manager/manager-report/performance-compare',
        iClass: 'fa fa-compass sideIcon',
      },
      {
        title: 'Menu.ZoomOnEmployee',
        refName: 'manager-report/zoom-on-employee',
        routerLink: '/Manager/manager-report/zoom-on-employee',
        iClass: 'fa  fa-search-plus sideIcon',
      },
      {
        title: 'Menu.Engagement',
        refName: 'manager-report/engagement',
        routerLink: '/Manager/manager-report/engagement',
        iClass: 'fa fa-fire sideIcon',
      },
    ];

    return menus;
  }

  /**
   * Submenus Team Administration
   */
  getTeamAdministrationMenuItems(userInfo) {
    let menus = [];

    if (userInfo.hasObjectiveCycles) {
      menus.push({
        title: 'Menu.Cycles',
        refName: 'team_cycles',
        iClass: 'fa fa-repeat sideIcon',
        routerLink: `/Manager/cycles`,
        subChild: [],
      });
    }
    menus.push({
      title: 'Menu.PerformanceReviews',
      refName: 'PerformanceReviews',
      iClass: 'fa fa-clipboard',
      routerLink: `/Manager/performance-review`,
      subChild: [],
    });
    if (environment.ShowManagerEvaluations) {
      menus.push({
        title: 'Menu.Evaluations',
        refName: 'evaluations',
        iClass: 'fa fa-star-o sideIcon',
        routerLink: `/Manager/evaluations`,
        subChild: [],
      });
    }

    return menus;
  }

  getCompanyAnaliticsMenu(userInfo) {
    let menus = [
      {
        title: 'Menu.Performance',
        refName: 'company-report/performance',
        routerLink: '/Company/company-report/performance',
        iClass: 'fa fa-trophy sideIcon',
      },
      {
        title: 'Menu.PerformanceCompare',
        refName: 'company-report/performance-compare',
        routerLink: '/Company/company-report/performance-compare',
        iClass: 'fa fa-compass sideIcon',
      },
      {
        title: 'Menu.ZoomOnEmployee',
        refName: 'company-report/zoom-on-employee',
        routerLink: '/Company/company-report/zoom-on-employee',
        iClass: 'fa fa-search-plus sideIcon',
      },
      {
        title: 'Menu.Engagement',
        refName: 'company-report/engagement',
        routerLink: '/Company/company-report/engagement',
        iClass: 'fa fa-fire sideIcon',
      },
    ];
    return menus;
  }
  /**
   * Company features Menus
   * @param userInfo
   */
  getCompanyFeatureSettingsMenu(userInfo) {
    let menus = [];

    if (userInfo.companyHasToken) {
      menus.push({
        title: 'Menu.AssignToken',
        refName: 'AssignToken',

        iClass: 'fa fa-database sideIcon',
        routerLink: `/CompanyAdmin/assign-token`,
        subChild: [],
      });
    }

    if (userInfo.hasObjectiveCycles) {
      menus.push({
        title: 'Menu.Cycles',
        refName: 'acompanies_cycles',
        iClass: 'fa fa-repeat sideIcon',
        routerLink: `/CompanyAdmin/companies/cycles/${userInfo.belongingCompanyId}/global`,
        subChild: [],
        active: true,
      });
    }

    if (userInfo.hasPatPrize) {
      menus.push({
        title: 'Menu.PatPrizes',
        refName: 'patprizes',
        iClass: 'fa fa-gift sideIcon',
        routerLink: `/CompanyAdmin/patprizes/${userInfo.belongingCompanyId}`,
        subChild: [],
      });
    }

    if (userInfo.hasLTI) {
      menus.push({
        title: 'Menu.Plans',
        refName: 'plans',
        routerLink: '/AdminLTI/plans',
        iClass: 'fa fa-handshake-o sideIcon',
      });

      menus.push({
        title: 'Menu.Results',
        refName: 'results',
        routerLink: '/AdminLTI/results',
        iClass: 'fa fa-check-square sideIcon',
      });
    }

    if (userInfo.hasSurveys) {
      menus.push({
        title: 'Menu.Surveys.Surveys',
        refName: 'Surveys',
        iClass: 'fa fa-list-ol sideIcon',
        routerLink: `/Surveys/CompanyAdmin`,
        subChild: [],
      });
    }

    if (userInfo.hasColorCustomizations) {
      menus.push({
        title: 'Menu.Colors',
        refName: 'Colors',
        iClass: 'fa fa-paint-brush sideIcon',
        routerLink: `/CompanyAdmin/Customizations/Colors`,
        subChild: [],
      });
    }

    return menus;
  }

  getIntegrationsSettingsMenu(userInfo) {
    let menus = [];
    menus.push({
      title: 'Slack',
      refName: 'slack',
      iClass: undefined,
      image: 'assets/images/integrations/slack.png',
      routerLink: `/Integrations/slack`,
      subChild: [],
    });
    // menus.push(
    //   {
    //     title: 'MS-Teams',
    //     refName: 'msteams',
    //     iClass: undefined,
    //     image: 'assets/images/integrations/teams.png',
    //     routerLink: `/Integrations/todo`,
    //     subChild: []
    //   });
    //   menus.push(
    //     {
    //       title: 'Google WS',
    //       refName: 'google',
    //       iClass: undefined,
    //       image: 'assets/images/integrations/google.png',
    //       routerLink: `/Integrations/todo`,
    //       subChild: []
    //     });
    //     menus.push(
    //       {
    //         title: 'Asana',
    //         refName: 'asana',
    //         iClass: undefined,
    //         image: 'assets/images/integrations/asana.png',
    //         routerLink: `/Integrations/todo`,
    //         subChild: []
    //       });
    //   menus.push(
    //     {
    //       title: 'Tableau',
    //       refName: 'tableau',
    //       iClass: undefined,
    //       image: 'assets/images/integrations/tableau.png',
    //       routerLink: `/Integrations/todo`,
    //       subChild: []
    //     });
    //     menus.push(
    //       {
    //         title: 'SSO',
    //         refName: 'sso',
    //         iClass: undefined,
    //         image: 'assets/images/integrations/SSO.png',
    //         routerLink: `/Integrations/todo`,
    //         subChild: []
    //       });
    return menus;
  }
  /**
   * Submenu User Info
   * @param userInfo
   */
  getCompanyUserSettingsMenu(userInfo) {
    let menus = [];
    menus.push({
      title: 'Menu.MyCompany',
      refName: 'acompanies',
      iClass: 'fas fa-building sideIcon',
      routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/company/global`,
      subChild: [],
    });
    if (userInfo.hasColorCustomizations) {
      menus.push({
        title: 'Menu.Colors',
        refName: 'Colors',
        iClass: 'fa fa-paint-brush sideIcon',
        routerLink: `/CompanyAdmin/Customizations/Colors`,
        subChild: [],
      });
    }
    menus.push({
      title: 'Menu.Employees',
      refName: 'acompanies_employees',
      iClass: 'fas fa-user sideIcon',
      routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/employees/global`,
      subChild: [],
    });

    // menus.push({
    //   title: "Menu.OrganisationalChart",
    //   refName: "orgchart",
    //   iClass: "fa fa-sitemap sideIcon",
    //   routerLink: `/User/orgchart`,
    //   subChild: [],
    // });

    menus.push({
      title: 'Menu.Teams',
      refName: 'acompanies_teams',
      iClass: 'fas fa-users sideIcon',
      routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/teams/global`,
      subChild: [],
    });

    menus.push({
      title: 'Menu.Roles',
      refName: 'acompanies_roles',
      iClass: 'fas fa-user-md sideIcon',
      routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/roles/global`,
      subChild: [],
    });

    if (userInfo.hasScalesCustomization) {
      menus.push({
        refName: 'acompanies_scales',
        title: 'Menu.Scales',
        routerLink: `/CompanyAdmin/companies/scales/${userInfo.belongingCompanyId}`,
        iClass: 'fas fa-star',
      });
    }
    if (userInfo.hasSkills) {
      menus.push({
        title: 'Menu.Skills',
        refName: 'acompanies_skills',
        iClass: 'fas fa-wrench sideIcon',
        routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/skills/global`,
        subChild: [],
      });
    }

    if (userInfo.hasBehaviors) {
      menus.push({
        title: 'Menu.Behaviors',
        refName: 'acompanies_behaviors',
        iClass: 'fa fa-american-sign-language-interpreting sideIcon',
        routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/behaviors/global`,
        subChild: [],
      });
    }
    /* HIDE_FOR_DEVELOPMENT 

    menus.push({
      title: 'Menu.Activities',
      refName: 'acompanies_activities',
      iClass: 'fas fa-tasks sideIcon',
      routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/activities/global`,
      subChild: [],
    });

     HIDE_FOR_DEVELOPMENT */

    if (environment.ShowGoals) {
      menus.push({
        title: 'Menu.Goals',
        refName: 'acompanies_goals',
        iClass: 'fas fa-thumbtack sideIcon',
        routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/goals/global`,
        subChild: [],
      });
    }
    /* HIDE_FOR_DEVELOPMENT 
    if (environment.ShowProjectTeams) {
      menus.push({
        title: 'Menu.ProjectTeams',
        refName: 'acompanies_pteams',
        iClass: 'fa fa-folder-open sideIcon',
        routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/projectTeams/global`,
        subChild: [],
      });
    }
     HIDE_FOR_DEVELOPMENT */

    if (userInfo.hasObjectiveCycles) {
      menus.push({
        refName: 'acompanies_objcategories',
        title: 'Menu.ObjectivesCategories',
        routerLink: `/CompanyAdmin/companies/obj-categories/${userInfo.belongingCompanyId}`,
        iClass: 'fas fa-dot-circle',
        subChild: [],
      });
      // menus.push(
      //   {
      //     title: 'Menu.Cycles',
      //     refName: 'acompanies_cycles',
      //     iClass: 'fa fa-repeat sideIcon',
      //     routerLink: `/CompanyAdmin/companies/cycles/${userInfo.belongingCompanyId}/global`,
      //     subChild: []
      //   });
    }

    // if (userInfo.companyHasToken) {
    //   // menus.push(
    //   //   {
    //   //     title: 'Menu.AssignToken',
    //   //     refName: 'AssignToken',

    //   //     iClass: 'fa fa-database sideIcon',
    //   //     routerLink: `/CompanyAdmin/assign-token`,
    //   //     subChild: []
    //   //   });
    //   menus.push({
    //     title: "Menu.TokenSettings",
    //     refName: "TokenSettings",
    //     stackedIcons: ["fa fa-database fa-stack-2x", "fa fa-cog fa-stack-1x "],
    //     iClass: "fa fa-database sideIcon",
    //     routerLink: `/CompanyAdmin/token-settings/global`,
    //     subChild: [],
    //   });
    // }

    menus.push({
      title: 'Menu.Integrations',
      refName: 'menu/my-integrations',
      iClass: 'fas fa-plug sideIcon',
      routerLink: `/TileMenu/menu/my-integrations`,
      subChild: [],
    });

    menus.push({
      title: 'Menu.Logs',
      refName: 'acompanies_logs',
      iClass: 'fas fa-archive sideIcon',
      routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/logs/global`,
      subChild: [],
    });

    // menus.push({
    //   title: "Menu.InapproriateReports",
    //   refName: "acompanies_inapproriate",
    //   iClass: "fas fa-radiation-alt sideIcon",
    //   routerLink: `/CompanyAdmin/inappropriate/global`,
    //   subChild: [],
    // });

    // if (userInfo.hasObjectiveCycles) {
    //   menus.push(
    //     {

    //       refName: 'acompanies_objcategories',
    //       title: 'Menu.ObjectivesCategories',
    //       routerLink: `/CompanyAdmin/companies/obj-categories/${userInfo.belongingCompanyId}`,
    //       iClass: 'fa fa-podcast',
    //       subChild: []
    //     });
    //   menus.push(
    //     {
    //       title: 'Menu.Cycles',
    //       refName: 'acompanies_cycles',
    //       iClass: 'fa fa-repeat sideIcon',
    //       routerLink: `/CompanyAdmin/companies/cycles/${userInfo.belongingCompanyId}/global`,
    //       subChild: []
    //     });
    // }

    // if (userInfo.hasPatPrize) {
    //   menus.push(
    //     {
    //       title: 'Menu.PatPrizes',
    //       refName: 'patprizes',
    //       iClass: 'fa fa-gift sideIcon',
    //       routerLink: `/CompanyAdmin/patprizes/${userInfo.belongingCompanyId}`,
    //       subChild: []
    //     }
    //   );
    // }

    return menus;
  }

  /**
   * Company features Menus
   * @param userInfo
   */
  getLocalFeatureSettingsMenu(userInfo) {
    let menus = [];

    if (userInfo.companyHasToken) {
      menus.push({
        title: 'Menu.AssignToken',
        refName: 'AssignToken',

        iClass: 'fa fa-database sideIcon',
        routerLink: `/CompanyAdmin/assign-token`,
        subChild: [],
      });
    }

    if (userInfo.hasObjectiveCycles) {
      menus.push({
        title: 'Menu.Cycles',
        refName: 'acompanies_cycles',
        iClass: 'fa fa-repeat sideIcon',
        routerLink: `/CompanyAdmin/companies/cycles/${userInfo.belongingCompanyId}/local`,
        subChild: [],
      });
    }

    if (userInfo.hasPatPrize) {
      menus.push({
        title: 'Menu.PatPrizes',
        refName: 'patprizes',
        iClass: 'fa fa-gift sideIcon',
        routerLink: `/CompanyAdmin/patprizes/${userInfo.belongingCompanyId}`,
        subChild: [],
      });
    }

    if (userInfo.hasSurveys) {
      menus.push({
        title: 'Menu.Surveys.Surveys',
        refName: 'Surveys',
        iClass: 'fa fa-list-ol sideIcon',
        routerLink: `/Surveys/CompanyAdmin`,
        subChild: [],
      });
    }

    return menus;
  }

  /**
   * Submenu User Info
   * @param userInfo
   */
  getLocalUserSettingsMenu(userInfo) {
    let menus = [];
    //TODO=> REINSERIRE QUANDO PRONTO
    //---------------------------------------------
    /* menus.push(
        {
          title: 'Company.Division',
          refName: 'acompanies',
          iClass: 'fas fa-landmark sideIcon',
          routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/division/local`,
          subChild: []
        });*/
    //---------------------------------------------
    menus.push({
      title: 'Menu.Employees',
      refName: 'acompanies_employees',
      iClass: 'fas fa-user sideIcon',
      routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/employees/local`,
      subChild: [],
    });

    // menus.push({
    //   title: "Menu.OrganisationalChart",
    //   refName: "orgchart",
    //   iClass: "fa fa-sitemap sideIcon",
    //   routerLink: `/User/orgchart`,
    //   subChild: [],
    // }); hide to local-admin

    menus.push({
      title: 'Menu.Teams',
      refName: 'acompanies_teams',
      iClass: 'fas fa-users sideIcon',
      routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/teams/local`,
      subChild: [],
    });

    menus.push({
      title: 'Menu.Roles',
      refName: 'acompanies_roles',
      iClass: 'fas fa-user-md sideIcon',
      routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/roles/local`,
      subChild: [],
    });

    // if (userInfo.hasScalesCustomization) {
    //   menus.push({
    //     refName: "acompanies_scales",
    //     title: "Menu.Scales",
    //     routerLink: `/CompanyAdmin/companies/scales/${userInfo.belongingCompanyId}`,
    //     iClass: "fa fa-star",
    //   });
    // } hide to local-admin
    if (userInfo.hasSkills) {
      menus.push({
        title: 'Menu.Skills',
        refName: 'acompanies_skills',
        iClass: 'fas fa-wrench sideIcon',
        routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/skills/local`,
        subChild: [],
      });
    }
    if (userInfo.hasBehaviors) {
      menus.push({
        title: 'Menu.Behaviors',
        refName: 'acompanies_behaviors',
        iClass: 'fa fa-american-sign-language-interpreting sideIcon',
        routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/behaviors/local`,
        subChild: [],
      });
    }
    /* HIDE_FOR_DEVELOPMENT 
    if (environment.ShowProjectTeams) {
      menus.push({
        title: 'Menu.ProjectTeams',
        refName: 'acompanies_pteams',
        iClass: 'fas fa-folder-open sideIcon',
        routerLink: `/CompanyAdmin/companies/details/${userInfo.belongingCompanyId}/projectTeams/local`,
        subChild: [],
      });
    }
     HIDE_FOR_DEVELOPMENT */

    if (userInfo.hasObjectiveCycles) {
      menus.push({
        refName: 'acompanies_objcategories',
        title: 'Menu.ObjectivesCategories',
        routerLink: `/CompanyAdmin/companies/obj-categories/${userInfo.belongingCompanyId}`,
        iClass: 'fas fa-dot-circle',
        subChild: [],
      });
    }

    menus.push({
      title: 'Menu.Integrations',
      refName: 'menu/my-integrations',
      iClass: 'fas fa-plug sideIcon',
      routerLink: `/TileMenu/menu/my-integrations`,
      subChild: [],
    });

    // if (userInfo.companyHasToken) {
    //   menus.push({
    //     title: "Menu.TokenSettings",
    //     refName: "TokenSettings",
    //     stackedIcons: ["fa fa-database fa-stack-2x", "fa fa-cog fa-stack-1x "],
    //     iClass: "fa fa-database sideIcon",
    //     routerLink: `/CompanyAdmin/token-settings/local`,
    //     subChild: [],
    //   });
    // }

    // menus.push({
    //   title: "Menu.InapproriateReports",
    //   refName: "acompanies_inapproriate",
    //   iClass: "fas fa-radiation-alt sideIcon",
    //   routerLink: `/CompanyAdmin/inappropriate/local`,
    //   subChild: [],
    // });

    return menus;
  }
  /**
   * Submenus Super User Administration
   */
  getSUMainMenuItems() {
    var items = [
      {
        title: 'Menu.AdminUsers',
        refName: 'user/list',
        iClass: 'fas fa-users sideIcon',
        routerLink: '/SuperAdmin/user',
      },
      {
        title: 'Menu.Companies',
        refName: 'companies',
        iClass: 'fa fa-cubes sideIcon',
        routerLink: '/SuperAdmin/companies',
      },
      {
        title: 'Log',
        refName: 'log',
        iClass: 'fas fa-pump-medical sideIcon',
        routerLink: '/SuperAdmin/log',
      },
    ];

    return items;
  }
  /**
   * Submenus Super User Administration
   */
  getSUAnalysis() {
    var items = [
      {
        title: 'Tableau',
        refName: 'admin/Tableau',
        iClass: 'fa fa-table sideIcon',
        routerLink: '/SuperAdmin/tableau',
      },
    ];

    return items;
  }
  /**
   * Submenys Super User Libraries
   */
  getLibrariesMenuItems() {
    var items = [
      {
        title: 'Menu.Languages',
        refName: 'language/list',
        routerLink: '/SuperAdmin/language',
        iClass: 'fa fa-globe',
      },
      {
        title: 'Menu.Roles',
        refName: 'roles/roles',
        routerLink: '/SuperAdmin/roles',
        iClass: 'fa fa-graduation-cap',
      },
      {
        refName: 'objectives/objectives-categories',
        title: 'Menu.ObjectivesCategories',
        routerLink: '/SuperAdmin/objectives/objectives-categories',
        iClass: 'fa fa-podcast',
      },
      {
        refName: 'skills/skill-categories',
        title: 'Menu.SkillCategories',
        routerLink: '/SuperAdmin/skills/skill-categories',
        iClass: 'fa fa-certificate',
      },
      {
        refName: 'skills/skill-library',
        title: 'Menu.Skills',
        routerLink: '/SuperAdmin/skills',
        iClass: 'fa fa-briefcase',
      },
      {
        refName: 'scales/editor',
        title: 'Menu.Scales',
        routerLink: '/SuperAdmin/gscales',
        iClass: 'fa fa-signal',
      },
      {
        refName: 'pricing-plans/list',
        title: 'Menu.PricingPlans',
        routerLink: '/SuperAdmin/pricing-plans',
        iClass: 'fa fa-credit-card',
      },
    ];

    return items;
  }

  getCompanyAdminSurveysMenuItems() {
    var items = [
      {
        title: 'Menu.Surveys.Modules',
        refName: 'surveys-library-modules',
        routerLink: '/Surveys/CompanyAdmin/Modules',
        iClass: 'fa fa-podcast',
      },
      {
        title: 'Menu.Surveys.Questions',
        refName: 'surveys-library-questions',
        routerLink: '/Surveys/CompanyAdmin/Questions',
        iClass: 'fa fa-podcast',
      },
      {
        title: 'Menu.Surveys.Manage',
        refName: 'surveys-manage',
        routerLink: '/Surveys/CompanyAdmin/Manage',
        iClass: 'fa fa-podcast',
      },
    ];

    return items;
  }

  removeMenu(ref, menus) {
    menus.forEach((item, index) => {
      if (item.refName === ref) menus.splice(index, 1);
    });
  }
}
