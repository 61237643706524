import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Input,
  HostListener,
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CoreService } from 'app/shared/services/core.service';
import { SharedService } from 'app/shared/services/shared.service';
import { IosAutocompleteComponent } from '../ios-autocomplete/ios-autocomplete.component';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'app-ask-evaluation',
  templateUrl: './ask-evaluation.component.html',
  styleUrls: [
    './ask-evaluation.component.scss',
    '../../../common_styles.scss',
    '../common_popup_styles.scss',
  ],
  providers: [
    Keyboard,
    {
      provide: BsDropdownConfig,
      useValue: { autoClose: true },
    },
  ],
})
export class AskEvaluationComponent implements OnInit {
  @Input() evaluationEmployees = [];
  iOS: boolean =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  companyGoals: any;
  selectedGoals = [];
  isGoalProcessing: boolean = false;
  selectedActivity: any = [];
  companyActivities: any;
  isActivityProcessing: boolean = true;
  isAfterContentChecked: boolean = false;
  listOfEmp: any;
  user_Info: any;
  skillArr: any;
  behaviorArr: any;
  description: any = '';
  currentEmployeeId: any;
  selectedTeams_Employees = [];
  selectedSkills = [];
  selectedBehaviors = [];
  selectedEmployees: any;
  askDays: number = 30;
  totalDays: number = 30;
  isInvalid: boolean = false;
  isPreSelected: boolean = false;
  show = true;
  isBehaviorProcessing: boolean = true;
  isSkillProcessing: boolean = true;
  settingsTeamEmployee = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
  };
  isSubmit: boolean = false;
  dropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
  };

  radioType: string = '';

  selectedItemIOS: any[] = [];
  iosModalRef: any;

  eval_arr: any[] = [];

  filterSkillChanged: Subject<any> = new Subject<any>();
  filteredSkills: any;
  filteredBehaviors: any;
  filteredObjectives: any;

  @Output() postAsk = new EventEmitter();
  public onClose: Subject<any>;

  check: boolean = true;
  @Output() checkDefine: EventEmitter<boolean> = new EventEmitter();
  reloadAct: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public coreService: CoreService,
    public sharedService: SharedService,
    private modalService: BsModalService,
    private translate: TranslateService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.user_Info = this.coreService.getUserInfo();
    this.onClose = new Subject();

    // this.user_Info.EvaluationScale.Values.forEach((element: any) => {
    //   let trans = element.Translations.find(x=>x.LanguageCode===this.user_Info.language);
    //   let desc=element.Description;
    //   let label= element.Name;
    //   if(trans){
    //     desc=trans.Description;
    //     label= trans.Name;
    //   }
    //   this.eval_arr.push({ "rate": element.Value, 'activeRate': 0, "label": label ,"description":desc });
    // });

    this.filterSkillChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((model) => {
        if (!model) return;

        switch (model.type) {
          case 'SKILLS':
            this.filteredSkills = this.skillArr.filter((rec: any) =>
              rec.Name.toLowerCase().trim().includes(model.item)
            );

            this.filteredSkills.sort((a: any, b: any) => {
              return a.selected ? -1 : 0;
            });

            break;

          case 'BEHAVIORS':
            this.filteredBehaviors = this.behaviorArr.filter((rec: any) =>
              rec.Name.toLowerCase().trim().includes(model.item)
            );

            this.filteredBehaviors.sort((a: any, b: any) => {
              return a.selected ? -1 : 0;
            });
            break;

          case 'GOALS':
            this.filteredObjectives = this.companyGoals.filter((rec: any) =>
              rec.Name.toLowerCase().trim().includes(model.item)
            );

            this.filteredObjectives.sort((a: any, b: any) => {
              return a.selected ? -1 : 0;
            });
            break;
        }
      });
    this.initData();
  }

  initData() {
    var list = this.evaluationEmployees || [];
    this.listOfEmp = list;
    // console.log(this.listOfEmp);

    list.forEach((element: any) => {
      if (element.Id && element.Id.indexOf(':') < 0) {
        if (element.Type == 'Team') {
          element['Name'] = element.Name + ' Team';
          element['Id'] = 't:' + element['Id'];
        } else {
          element['Name'] = element.Name;
          element['Id'] = 'e:' + element['Id'];
        }
      }

      if (element.isPreselect === true && element.Type === 'Employee') {
        if (element.FirstName) {
          element['Name'] = element.FirstName + ' ' + element.SecondName;
        }
        this.onSelectTeamEmployeeChange([element.Id]);
        this.selectedTeams_Employees = [element.Id];
        this.selectedEmployees = [element];
        this.isPreSelected = true;
      }
    });

    this.isAfterContentChecked = true;
  }

  ngAfterContentInit() {
    this.refreshRadioSelection();
  }

  refreshRadioSelection() {
    if (this.user_Info.hasSkills) {
      this.radioType = 'skill';
    } else if (this.user_Info.hasObjectives) {
      this.radioType = 'goal';
    } else if (this.user_Info.hasBehaviors) {
      this.radioType = 'behavior';
    }
  }

  onSelectTeamEmployeeRemove(event) {
    this.skillArr = [];
    this.behaviorArr = [];
    this.companyGoals = [];
    this.selectedBehaviors = [];
    this.selectedGoals = [];
    this.selectedActivity = [];

    this.filteredSkills = [];
    this.filteredBehaviors = [];
    this.filteredObjectives = [];
  }

  onSelectTeamEmployeeChange(event) {
    let teamId = [];
    let employeeId = [];
    this.currentEmployeeId = this.user_Info.linkedEmployeeId;
    this.selectedTeams_Employees = event;
    if (this.selectedTeams_Employees.length > 0) {
      this.listOfEmp.forEach((el) => {
        this.selectedTeams_Employees.forEach((item: any) => {
          var id = item.substring(2);
          var t = item.substring(0, 1);
          if (el.Id == item) {
            t == 'e' ? employeeId.push(id) : teamId.push(id);
          }
        });
      });
      this.getSkillId(employeeId, teamId);
      this.getBehaviorId(employeeId, teamId);
      this.getGoalId(employeeId, teamId);
    }
  }
  getGoalId(employeeId, teamId) {
    var reqEmployee = [];
    reqEmployee.push(this.user_Info.linkedEmployeeId);
    let reqBody = {
      CurrentEmployeeId: this.user_Info.linkedEmployeeId,
      Employees: reqEmployee,
      Teams: [],
    };
    this.getGoals(reqBody);
  }

  getGoals(reqBody) {
    this.sharedService.getGoals(reqBody).subscribe(
      (data: any) => {
        // console.log('getGoals', data);
        this.isGoalProcessing = false;
        this.companyGoals = data;
        this.companyGoals.sort((a: any, b: any) => {
          return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
            ? -1
            : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
              ? 1
              : 0;
        });
        this.filteredObjectives = data;
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }
  getActivityId(employeeId, teamId) {
    let reqBody = {
      CurrentEmployeeId: this.user_Info.linkedEmployeeId,
      Employees: employeeId,
      Teams: teamId,
    };
    this.getActivities(reqBody);
  }

  getActivities(reqBody) {
    this.sharedService.getCompanyActivities(reqBody).subscribe(
      (data: any) => {
        this.isActivityProcessing = false;
        this.companyActivities = data;
        this.companyActivities.sort((a: any, b: any) => {
          return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
            ? -1
            : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
              ? 1
              : 0;
        });
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }
  getSkillId(employeeId, teamId) {
    var reqEmployee = [];
    reqEmployee.push(this.user_Info.linkedEmployeeId);
    let reqBody = {
      CurrentEmployeeId: this.user_Info.linkedEmployeeId,
      Employees: reqEmployee,
      Teams: [],
    };
    this.getSkills(reqBody);
  }

  getSkills(reqBody) {
    this.sharedService.getAskskills(reqBody).subscribe(
      (data: any) => {
        this.skillArr = data;
        this.isSkillProcessing = false;
        this.filteredSkills = data;
        this.filteredSkills.sort((a: any, b: any) => {
          return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
            ? -1
            : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
              ? 1
              : 0;
        });
      },
      (err: any) => {
        console.log('errrr');
      }
    );
  }

  getBehaviorId(employeeId, teamId) {
    var reqEmployee = [];
    reqEmployee.push(this.user_Info.linkedEmployeeId);
    let reqBody = {
      CurrentEmployeeId: this.user_Info.linkedEmployeeId,
      Employees: reqEmployee,
      Teams: [],
    };
    this.getBehaviors(reqBody);
  }

  getBehaviors(reqBody) {
    this.sharedService.getAskBehaviors(reqBody).subscribe(
      (data: any) => {
        this.behaviorArr = data;
        this.isBehaviorProcessing = false;
        this.filteredBehaviors = data;
        this.filteredBehaviors.sort((a: any, b: any) => {
          return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
            ? -1
            : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
              ? 1
              : 0;
        });
      },
      (err: any) => {
        console.log('errrr');
      }
    );
  }

  onChange(days) {
    this.isInvalid = !Number.isInteger(Number(days));

    console.log('onChange', days, this.isInvalid);
    if (!this.isInvalid) {
      if (days <= this.totalDays) {
        this.isInvalid = false;
      }
      if (days > this.totalDays || days < 1) {
        this.isInvalid = true;
        this.askDays = this.totalDays;
        $('input[name="days"]').val(this.askDays);
      }
      // } else {
      // this.askDays = this.totalDays;
      // $('input[name="days"]').val(this.askDays);
    } else if (days > this.totalDays) {
      this.askDays = this.totalDays;
      $('input[name="days"]').val(this.askDays);
    } else if (days < 1) {
      this.askDays = 1;
      $('input[name="days"]').val(this.askDays);
    }
    this.ref.detectChanges();
  }

  sendingConfirmation: boolean = false;
  onConfirm() {
    if (this.disableSend() || this.sendingConfirmation) return;

    this.sendingConfirmation = true;

    this.isSubmit = true;
    let employeeId = [];
    let teamId = [];
    let skillId = [];
    let behaviorId = [];
    let goalId = [];

    // this.listOfEmp.forEach(el => {
    //   form.sel_emp_team.forEach(item => {
    //     var id = item.substring(2);
    //     var t = item.substring(0, 1);
    //     if (el.Id == item) {
    //       (t == 't') ? teamId.push({ "Id": id }) : employeeId.push({ "Id": id });
    //     }
    //   });
    // });

    this.selectedEmployees.forEach((item: any) => {
      if (item.Type === 'Employee') {
        employeeId.push({ Id: item.Id.replace('e:', '') });
      } else {
        teamId.push({ Id: item.Id.replace('t:', '') });
      }
    });

    if (this.selectedSkills) {
      this.selectedSkills.forEach((item) => {
        skillId.push({ Id: item.Id });
      });
    }

    if (this.selectedBehaviors) {
      this.selectedBehaviors.forEach((item) => {
        behaviorId.push({ Id: item.Id });
      });
    }

    if (this.selectedGoals) {
      this.selectedGoals.forEach((item) => {
        goalId.push({ Id: item.Id });
      });
    }

    let newAsk = {
      Author: {
        Id: this.user_Info.linkedEmployeeId,
      },
      Target: {
        Employees: employeeId,
        Teams: teamId,
      },
      NumDays: this.askDays,
      Description: this.description,
      Skills:
        skillId.length > 0
          ? skillId
          : behaviorId.length > 0
            ? behaviorId
            : null,
      Goals: goalId.length > 0 ? goalId : null,
      Activity: null,
    };

    // console.log("123",JSON.stringify(newAsk));

    this.askEval(newAsk);
  }

  askEval(newAsk) {
    this.sharedService.askEval(newAsk).subscribe(
      (data: any) => {
        this.postAsk.emit(data);
        // this.coreService.toasterMessage(
        //   'success',
        //   'wall',
        //   this.coreService.getTranslation("Messages.AskSent")
        // );
        this.coreService.setShowPublished({ type: 'Feedback', isEdit: false });
        this.emptySelected();
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
        this.isSubmit = false;
      }
    );

    this.onClose.next(newAsk);
    this.bsModalRef.hide();
  }

  emptySelected() {
    this.listOfEmp.forEach((element) => {
      element.selected = false;
    });
  }

  // iosAutocompleteDialog(selectionViewType: string) {

  //   let dataList: any = [];
  //   let title: string = '';
  //   switch (selectionViewType) {
  //     case 'EMPLOYEES_TEAMS':
  //       dataList = this.listOfEmp;
  //       title = this.translate.instant('Generic.ChooseEmployeeOrTeam');
  //       break;
  //     case 'SKILLS':
  //       dataList = this.skillArr;
  //       title = this.translate.instant('Generic.ChooseSkill');
  //       break;
  //     case 'GOALS':
  //       dataList = this.companyGoals;
  //       title = this.translate.instant('Generic.ChooseGoal');
  //       break;
  //     case 'BEHAVIORS':
  //       dataList = this.behaviorArr;
  //       title = this.translate.instant('Generic.ChooseBehavior');
  //       break;
  //   }

  //   this.iosModalRef = this.modalService.show(IosAutocompleteComponent, { class: 'iosAutocompleteDialog', ignoreBackdropClick: true });
  //   this.iosModalRef.content.setData(dataList);
  //   this.iosModalRef.content.selectionMultiple = this.radioType !== 'goal';
  //   this.iosModalRef.content.selectionViewType = selectionViewType;
  //   this.iosModalRef.content.title = title;
  //   this.iosModalRef.content.getResponse.subscribe((data: any) => {
  //     this.selectedItemIOS[selectionViewType] = data.selected;

  //     switch (selectionViewType) {
  //       case 'EMPLOYEES_TEAMS':
  //         this.listOfEmp = data.originalList;
  //         this.onSelectTeamEmployeeChange(data.selected.map((item: any) => item.Id));
  //         this.selectedTeams_Employees = data.selected.map((item: any) => item.Id);
  //         this.selectedEmployees = data.selected;
  //         console.log(this.selectedEmployees);
  //         break;
  //       case 'SKILLS':
  //         this.skillArr = data.originalList;
  //         this.selectedSkills = data.selected.map((item: any) => item.Id);
  //         console.log(this.selectedSkills);

  //       case 'BEHAVIORS':
  //         this.behaviorArr = data.originalList;
  //         this.selectedBehaviors = data.selected.map((item: any) => item.Id);
  //         break;
  //       case 'GOALS':
  //         this.companyGoals = data.originalList;
  //         this.selectedGoals = data.selected.map((item: any) => item.Id);
  //         break;
  //     }

  //   });
  // }

  selectItem(event: any) {
    this.selectedTeams_Employees = [event.item];
    // console.log(this.selectedTeams_Employees);
  }

  onFilterListChange(event: any, item: any, type: string) {
    //console.log('onInputChange', event, item, type);
    if (event.keyCode !== 13) {
      this.filterSkillChanged.next({
        item: item,
        type: type,
      });
    }
  }
  displayCounter(count, type: string) {
    if (type == 'SKILL') {
      this.selectedSkills = count;
    } else if (type == 'BEHAVIOR') {
      this.selectedBehaviors = count;
    } else if (type == 'GOAL') {
      this.selectedGoals = count;
    }
  }
  // manageSelection(item: any, type: string) {
  //   item.selected = !item.selected;

  //   switch (type) {
  //     case 'SKILLS':
  //       if (item.selected) {
  //         this.selectedSkills.push(item.Id);
  //       } else {
  //         this.selectedSkills = this.selectedSkills.filter((id: string) => id !== item.Id);
  //       }
  //       break;

  //     case 'BEHAVIORS':
  //       if (item.selected) {
  //         this.selectedBehaviors.push(item.Id);
  //       } else {
  //         this.selectedBehaviors = this.selectedBehaviors.filter((id: string) => id !== item.Id);
  //       }
  //       break;

  //     case 'GOALS':
  //       if (item.selected) {
  //         this.selectedGoals.push(item.Id);
  //       } else {
  //         this.selectedGoals = this.selectedGoals.filter((id: string) => id !== item.Id);
  //       }
  //       break;
  //   }
  // }

  disableSend() {
    return (
      this.isSubmit ||
      !this.selectedEmployees ||
      this.selectedEmployees.length === 0 ||
      (this.radioType === 'skill' &&
        (!this.selectedSkills || this.selectedSkills.length === 0)) ||
      (this.radioType === 'behavior' &&
        (!this.selectedBehaviors || this.selectedBehaviors.length === 0)) ||
      (this.radioType === 'goal' &&
        (!this.selectedGoals || this.selectedGoals.length === 0))
    );
  }

  selectAll(event: any) {
    $(event.target).select();
  }
  onSelectedEmployee(data: any) {
    this.listOfEmp = data.originalList;
    this.onSelectTeamEmployeeChange(data.selected.map((item: any) => item.Id));
    this.selectedTeams_Employees = data.selected.map((item: any) => item.Id);
    this.selectedEmployees = data.selected;
    this.checkFill(this.selectedEmployees);
  }
  checkFill(data) {
    if (data.length > 0) {
      this.check = false;
    } else {
      this.check = true;
    }
    this.checkDefine.emit(this.check);
  }
  reloadActivity() {
    this.reloadAct = false;
    this.selectedSkills = [];
    this.selectedBehaviors = [];
    this.selectedGoals = [];
    this.description = '';
    setTimeout(() => {
      this.reloadAct = true;
    }, 10);
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
}
